import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { setupApi } from '../service/api-service';
import store from '../store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';

setupApi(store);

const persistor = persistStore(store);

export const Root: FC = () => {
  return (
    <div id='root'>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </div>
  );
};
