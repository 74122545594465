import {createAsyncThunk} from '@reduxjs/toolkit';
import { ApiError } from '../../model/api-error';
import { GenericMessage } from '../../model/constants';
import { ThemeSettings } from '../../model/theme-settings';
import {
  apiLogoUrl,
  apiHeaderBackground,
  apiAccentColor,
  apiGetTicketsByEventId,
} from '../../service/api-service';
import {RootState} from "../rootReducer";
import {checkinsDBService, db, ticketsDBService} from "../../service/db-service";
import {TicketDb, TicketResponseData} from '../../model/tse/ticket';
import { Event as TsEvent } from "../../model/event";
import moment from "moment/moment";
import {transformDataTicket} from "../../util/ticket-transform-data";
import {setLoadingManually} from "../slices/settingsSlice";
import {setEventLastUpdate} from "../slices/ticketSlice";
import {retrieveTicketsByEventId} from "./ticketThunk";

export const loadSettings = createAsyncThunk<ThemeSettings, void, { rejectValue: ApiError }>(
  'loadSettings',
  async (_: void, thunkApi) => {
    try {
      const headerBackgroundReq = apiHeaderBackground();
      const logoUrlReq = apiLogoUrl();
      const accentColorReq = apiAccentColor();

      const [accentColor, headerBackground, logoUrl] = await Promise.all([
        accentColorReq,
        headerBackgroundReq,
        logoUrlReq,
      ]);
      return {
        headerBackground,
        logoUrl,
        accentColor,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const setOfflineMode = createAsyncThunk<string[], string[], { rejectValue: ApiError }>(
  'setOfflineMode',
  async (eventIds: string[], thunkApi) => {
    try {
      if (eventIds.length === 0) {
        return [];
      }
      thunkApi.dispatch(setLoadingManually(true));
      const state = thunkApi.getState() as RootState;
      for (const eventId of eventIds) {
        const event = (await state.event.events.find((ev) => ev.id === eventId)) as TsEvent;
        await db.initializeOffline(event);
        const result = await apiGetTicketsByEventId(eventId, undefined, addTicketsToIndexDb);
        const lastUpdate = Math.max(0, ...result.map((ticket) => parseInt(moment(ticket.dateModifiedUtc).add(1, 's').format('x'))));
        thunkApi.dispatch(setEventLastUpdate({ eventId, lastUpdate }));
        thunkApi.dispatch(retrieveTicketsByEventId(eventId));
      }
      thunkApi.dispatch(existsPendingsWithErrors());
      thunkApi.dispatch(setLoadingManually(false));
      return eventIds;
    } catch (error) {
      const apiError = error as ApiError;
      thunkApi.dispatch(setLoadingManually(false));
      return thunkApi.rejectWithValue({
        code: 400,
        message: apiError.message || GenericMessage.UnknownError,
      } as ApiError);
    }
  },
);

const addTicketsToIndexDb = async (orders: TicketResponseData[]): Promise<void> => {
  const ticketsDb: TicketDb[] = [];
  orders.map(order => {
    const { lineItems } = order;
    lineItems.map(({ tickets }) => tickets.map(ticket => ticketsDb.push(transformDataTicket(order, ticket))));
  });
  await ticketsDBService.addTickets(ticketsDb);
}

export const existsPendingsWithErrors = createAsyncThunk<number, void, { rejectValue: ApiError }>(
  'existsPendingsWithErrors',
  async () => {
    return await checkinsDBService.existsPendingsWithErrors();
  },
);