/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, List, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link, useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { EventTitle } from '../display/event-title';
import { EventCalendar } from '../display/event-calendar';
import { isOrderCheckedIn } from '../../util/order-util';
import { OrderListItem } from '../display/order-list-item';
import { LoaderModal } from '../display/loader-modal';
import { FONT_BOLD } from '../../model/constants';
import { CheckCircle } from '../display/check-circle';
import { fixDateIfApplyAndCleanOffset} from '../../util/event-date-utils';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { cleanCheckin } from '../../store/slices/ticketSlice';
import {
  compareTicketsByTimeSlotAsc,
  getCheckinParams,
  getTimeSlotFromTicket,
  getTimeSlotLabel
} from '../../util/tickeet-utils';
import {
  selectEvents,
  selectEventsToScan, selectEventTimeSlots,
  selectIsMultipleEvents,
  selectSelectedEvent
} from '../../store/selectors';
import { OrderTicket } from '../../model/order';
import { i18n_t } from '../../translations/translate';
import { openModal} from "../../store/slices/modalSlice";
import ConfirmationModal from "../display/confirmation-modal";
import { checkinTicket } from "../../store/thunks/ticketThunk";
import { CheckInOverrideModal } from "../display/check-in-override-modal";
import { ticketsDBService } from "../../service/db-service";
import { TicketState } from "../../model/tse/order";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    marginTop: '1.25rem',
    marginBottom: '0.625rem',
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.error.main,
    },
  },
  detailsButton: {
    marginTop: '1.25rem',
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
  },
  openButton: {
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.info.main,
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  checkIcon: {
    width: '3.875rem',
    height: '3.875rem',
    color: theme.palette.success.main,
    marginBottom: '1.25rem',
  },
  removeIcon: {
    width: '3.875rem',
    height: '3.875rem',
    color: theme.palette.warning.main,
    marginBottom: '1.25rem',
  },
  emailInfo: {
    wordBreak: 'break-all',
  },
}));

export const OrderDetailPage: FC = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(true);
  const order = useAppSelector((state) => state.order.selectedOrder);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const [selectedOrderTicket, setSelectedOrderTicket] = useState<OrderTicket>();
  let event = useAppSelector(selectSelectedEvent);
  const eventsToScan = useAppSelector(selectEventsToScan);
  const events = useAppSelector(selectEvents);
  const eventTimeSlots = useAppSelector(selectEventTimeSlots);
  const [orderTickets, setOrderTickets] = useState<OrderTicket[]>([]);

  if (eventId && eventId !== 'multi-events') {
    event = eventsToScan.find((toScan) => toScan.event.id === eventId)?.event || null;
  }

  const { loading, errorMessage, checkin } = useAppSelector((state) => state.ticket);
  const dispatch = useDispatch();
  const fromAttendeePage = history.location.state && history.location.state.attendeePage ? true : false;

  useEffect(() => {
    return () => {
      dispatch(cleanCheckin());
    };
  }, []);


  useEffect(() => {
    if (order) {
      setShowDetails(true);
      const tickets: OrderTicket[] = [];
      order.lineItems.forEach((lineItem) => lineItem.tickets.forEach((ticket) => {
        tickets.push(ticket)
      }));
      setOrderTickets(tickets.sort(compareTicketsByTimeSlotAsc));
    }
  }, [order]);

  const confirmCheckinScan = React.useCallback(async () => {
    if(checkin){
      const { barcode } = checkin;
      if (!!barcode && !loading) {
        const ticket = await ticketsDBService.getTicketByBarcode(barcode);
        const recurrenceDateTime = ticket ? ticket.ticketTypeInfo.event.recurrenceDateTime : '';
        const checkinParam = getCheckinParams(eventsToScan, barcode, recurrenceDateTime, eventTimeSlots);
        checkinParam.forceConfirmation = true;
        dispatch(checkinTicket(checkinParam));
      }
    }
  }, [checkin]);

  const onConfirmCallback = useCallback(async () => {
    if (selectedOrderTicket) {
      const event = events.find(e => e.id === selectedOrderTicket.ticketTypeInfo.event?.id);
      if (event){
        const ticket = await ticketsDBService.getTicketByBarcode(selectedOrderTicket.ticketBarCode);
        const recurrenceDateTime = ticket ? ticket.ticketTypeInfo.event.recurrenceDateTime : '';
        const checkin = getCheckinParams(eventsToScan, selectedOrderTicket.ticketBarCode, recurrenceDateTime, eventTimeSlots);
        checkin.allowUnCheckin = true;
        dispatch(checkinTicket(checkin));
      }
    }
  }, [selectedOrderTicket, events]);

  const onCheckinState = async (ticket: OrderTicket) => {
    if (ticket.redemptionInfo.redeemed === true) {
      setSelectedOrderTicket(ticket);
      dispatch(openModal('confirmUncheckin'));
    } else {
      const event = events.find(e => e.id === ticket.ticketTypeInfo.event?.id);
      if (event) {
        const recurrenceDateTime = ticket.ticketTypeInfo.event?.recurrenceDateTime;
        const checkin = getCheckinParams(eventsToScan, ticket.ticketBarCode, recurrenceDateTime, eventTimeSlots);
        checkin.allowUnCheckin = true;
        dispatch(checkinTicket(checkin));
      }
    }
  };

  return (
    <>
      {event && order && (
        <Box padding='1.25rem'>
          <Box marginBottom='1.25rem' onClick={() => history.goBack()}>
            <Grid container alignItems='center'>
              <Grid item xs={1}>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Grid>
              <Grid item xs={4}>
                <EventCalendar weekday={true} date={fixDateIfApplyAndCleanOffset(event.eventStartDate)} />
              </Grid>
              <Grid item xs={7}>
                <EventTitle title={event?.title} venue={event?.venue?.name} />
              </Grid>
            </Grid>
          </Box>
          <Divider />

          <Box paddingTop='1.25rem' paddingBottom='1.25rem'>
            <Box textAlign='center'>
              {isOrderCheckedIn(order) ? (
                <CheckCircleIcon className={classes.checkIcon} />
              ) : (
                <RemoveCircleIcon className={classes.removeIcon} />
              )}
              <Typography variant='h4'>
                {order.purchaserInfo.firstName} {order.purchaserInfo.lastName}
              </Typography>

              {fromAttendeePage === false && (
                <Typography>Success! {order.purchaserInfo.firstName} is checked in!</Typography>
              )}

              <Box marginTop='1.25rem'>
                <Typography variant='body2' color='textSecondary'>
                  {order.purchaserInfo.purchaserEmailAddress}
                </Typography>

                <Typography variant='body1'>{i18n_t('orderNumber')} #{order.orderIdentifier}</Typography>
              </Box>

              {errorMessage !== null && <Typography color='error'>{errorMessage}</Typography>}
            </Box>

            {showDetails === true && (
              <Box paddingTop='1.25rem'>
                <Divider />
                <List>
                  {orderTickets.map((ticket) =>
                    ticket.ticketStatus !== TicketState.Canceled && (
                      <OrderListItem
                        key={ticket.id}
                        checkedIn={ticket.redemptionInfo.redeemed === true}
                        primaryText={ticket.ticketTypeInfo.tierType.name}
                        subtitle={ticket.ticketTypeInfo.ticketType?.name || ''}
                        secondaryText={`Barcode: ${ticket.ticketBarCode}`}
                        additionalInfoText={ticket.redemptionInfo.redeemed ? getTimeSlotFromTicket(ticket) : ''}
                        timeSlotDate={getTimeSlotLabel(ticket, event)}
                        ticketCount={1}
                        handleClick={async () => onCheckinState(ticket)}
                        icon={<CheckCircle checked={ticket.redemptionInfo.redeemed === true} />}
                      />
                    )
                  )}
                </List>

                <Box paddingTop='1.5rem'>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant='caption'>{i18n_t('purchased')}</Typography>
                      <Typography variant='body1'>
                        {format(new Date(order.datePurchasedUtc || ''), 'MMMM dd, yyyy')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='caption'>{i18n_t('purchaser')}</Typography>
                      <Typography variant='body1' paragraph>
                        {order.purchaserInfo.firstName} {order.purchaserInfo.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='caption'>{i18n_t('orderNumber')}</Typography>
                      <Typography variant='body1' paragraph>
                        {order.orderIdentifier}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='caption'>{i18n_t('email')}</Typography>
                      <Typography variant='body1' paragraph className={classes.emailInfo}>
                        {order.purchaserInfo.purchaserEmailAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}

            <Button
              variant='outlined'
              onClick={() => setShowDetails(!showDetails)}
              fullWidth
              className={classes.detailsButton}
            >
              <InfoIcon fontSize='small' className={classes.infoIcon} />
              {showDetails === false ? i18n_t('showOrderDetails') : i18n_t('hideOrderDetails')}
            </Button>
          </Box>

          <Divider />

          <Button
            variant='contained'
            color='primary'
            className={classes.closeButton}
            component={Link}
            to={`/event/${isMultipleEvents ? 'multi-events' : event?.id}`}
            fullWidth
          >
            {fromAttendeePage === true ? 'Back To Event' : i18n_t('closeScanner')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.openButton}
            onClick={() => history.goBack()}
            fullWidth
          >
            {fromAttendeePage === true ? 'Back to Attendee Search' : i18n_t('continueScanning')}
          </Button>
        </Box>
      )}
      <ConfirmationModal
        title='Unckeck ticket'
        description='Are you sure?'
        onConfirmCallback={onConfirmCallback}
      />
      <LoaderModal loading={loading} text={'Checking In...'} />
      <CheckInOverrideModal checkinScan={confirmCheckinScan} />
    </>
  );
};

export default OrderDetailPage;
