import { OrderResponse, BookForSlotReq, FilterOrderParams } from './../model/order';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ApiError } from '../model/api-error';
import {LocalStorageConf, TICKETS_LIMIT} from '../model/constants';
import { EventQueryRequest, EventResponse, TimeSlotsResponse, TimeSlotsResquest } from '../model/event';
import { Order } from '../model/order';
import { LoginResponse } from '../model/user';
import { CheckinStatus } from '../model/checkin';
import { Ticket, UncheckinResp } from '../model/ticket';
import { userLogout } from '../store/slices/userSlice';
import { OrderOptimizedResponse } from '../model/orderOptimized';
import { transformOrder } from '../util/order-util';
import { TicketCountResponse, TicketResponse, TicketResponseData } from "../model/tse/ticket";
import { TicketTypeResponse } from "../model/tse/ticket-type";
import moment from 'moment';
import { getClientToken, getIntegrationApiUrl } from '../util/tmp-fiba-utils';

const TSE_ENDPOINT = process.env.SCANNER_TSE_ENDPOINT;
const TSE_CLIENT_ID = process.env.SCANNER_TSE_CLIENT_ID;

let store;

console.log(process.env.SCANNER_TSE_ENDPOINT);


export const getRecoverTicketsDaysFrom = (): string | null => localStorage.getItem(LocalStorageConf.RecoverTicketsDaysFrom);
export const getRecoverTicketsDaysTo = (): string | null => localStorage.getItem(LocalStorageConf.RecoverTicketsDaysTo);
export const getSiteId = (): string | null => localStorage.getItem(LocalStorageConf.SiteId);
export const getToken = (): string | null => localStorage.getItem(LocalStorageConf.Token);
export const getLang = (): string | null => localStorage.getItem(LocalStorageConf.Lang);
export const getServiceTimeout = (): number => parseInt(localStorage.getItem(LocalStorageConf.Timeout) || '5000');
const getSiteUrl = (): string => `${TSE_ENDPOINT}/api/sites/${getSiteId()}`;

export const setupApi = (_store): void => {
  store = _store;

  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config?.url?.endsWith?.('/token')) {
        const token = getToken();
        if (token && config && config.headers) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err: AxiosError): Promise<ApiError> => {
      if (err?.config?.url !== '/token' && err.response && err.response.status === 401) {
        store.dispatch(userLogout());
        localStorage.removeItem(LocalStorageConf.Token);
      }
      throw {
        code: err?.response?.status || err.code,
        message: ((err?.response?.data || {}) as { errorMessage: string })?.errorMessage,
        data: err?.response?.data,
      } as ApiError;
    },
  );
};

export const apiLogin = async (username: string, password: string): Promise<LoginResponse> => {
  const body = {
    username,
    password,
    grant_type: 'password',
    client_id: TSE_CLIENT_ID,
  } as Record<string, string>;
  const res = await axios.post<LoginResponse>(`${TSE_ENDPOINT}/token`, new URLSearchParams(body).toString(), {
    headers: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
};

export const apiHeaderBackground = async (): Promise<string> => {
  const res = await axios.get(`${getSiteUrl()}/site-settings`);
  return res?.data?.siteAppearanceSettings?.header?.headerBackground?.color?.code;
};

export const apiLogoUrl = async (): Promise<string> => {
  const res = await axios.get(`${getSiteUrl()}/logo`);
  return res?.data?.photoInfo?.photoUrl;
};

export const apiAccentColor = async (): Promise<string> => {
  const res = await axios.get(`${getSiteUrl()}/checkout-widget-settings`);
  return res?.data?.items[0]?.accentColor;
};

export const apiFindEvents = async (query?: EventQueryRequest): Promise<EventResponse> => {
  const params = {
    hideExpiredEvents: true,
    include: 'venue',
    skipPaging: true,
    ...query,
  };
  const res = await axios.get<EventResponse>(`${getSiteUrl()}/events`, {
    params,
  });
  return res.data;
};

export const apiFindOrders = async (query: {
  eventId?: string;
  searchTerm?: string;
  skip?: number;
  take?: number;
  ticketBarCode?: string;
}): Promise<OrderResponse> => {
  const params: FilterOrderParams = query.ticketBarCode
    ? { ticketBarCode: query.ticketBarCode }
    : { orderStatusTypes: ['Confirmed', 'PartiallyRefunded'], ...query };

  if (query.eventId !== undefined) {
    params['eventSelection'] = { eventId: query.eventId };
    delete params.eventId;
  }
  const res = await axios.post<OrderResponse>(`${getSiteUrl()}/filter-orders?mode=checkin`, params);
  return res.data;
};

function sleep(ms: number, temp?: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(() => resolve(temp), ms));
}

export async function checkPromiseTimeout(promises: Promise<CheckinStatus>[], timeout: number): Promise<boolean> {
  const temp = Math.random();
  const timeoutPromise = sleep(timeout, temp);

  const result = await Promise.race([...promises, timeoutPromise]);

  return result === temp;
}

export const apiCheckinTicket = async (
  barCode: string,
  eventId: string,
  occurrence: string | null,
  overrideTicketOccurrence: boolean,
): Promise<CheckinStatus[]> => {
  const params = [{
    barCode,
    occurrence,
    overrideTicketOccurrence,
  }];
  try {
    const res = await axios.post<CheckinStatus[]>(`${getSiteUrl()}/events/${eventId}/redeem`, params);
    return res.data;
  } catch (error) {
    const apiError = error as ApiError;
    return [{
      success: false,
      errorMessage: apiError?.message,
    }] as CheckinStatus[];
  }
};

export const apiUncheckTicket = async (
  eventId: string,
  barCode: string,
  occurrence: string,
  overrideTicketOccurrence: boolean,
): Promise<boolean> => {
  const res = await axios.post<UncheckinResp>(`${getSiteUrl()}/events/${eventId}/delete-redemption`, {
    barCode,
    occurrence,
    overrideTicketOccurrence,
  });
  return res.data.success || false;
};

export const apiGetTicketWithBarCode = async (barCode: string): Promise<Ticket | null> => {
  try {
    const res = await axios.get(`${getSiteUrl()}/ticket/${barCode}`);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const apiGetOrderById = async (orderId: string): Promise<Order> => {
  const res = await axios.get<Order>(`${getSiteUrl()}/orders/${orderId}`);
  return res.data;
};

export const apiGetEventTimeSlots = async (query: TimeSlotsResquest): Promise<TimeSlotsResponse> => {
  const params = {
    fromDate: query.fromDate,
    toDate: query.toDate,
  };
  const res = await axios.get<TimeSlotsResponse>(`${getSiteUrl()}/events/${query.eventId}/booking-calendar`, {
    params,
  });
  return res.data;
};

export const apiGetBookBySlot = async (query: BookForSlotReq): Promise<OrderResponse> => {
  const params = {
    mode: query.mode,
    skip: query.skip,
    take: query.take,
  };
  const BOOK_URL = `/date/${query.date}/hour/${query.hour}/minute/${query.minute}/bookings`;
  const resp = await axios.get<OrderOptimizedResponse>(`${getSiteUrl()}/events/${query.eventId}${BOOK_URL}`, {
    params,
  });
  const orderResp = {} as OrderResponse;
  orderResp.items = resp.data.items.map((order) => transformOrder(order));
  return orderResp;
};

export const apiGetTicketsByEventId = async (
  eventId: string,
  lastUpdate?: number,
  foundPageCallback?: (ticketsResp: TicketResponseData[]) => Promise<void>,
): Promise<TicketResponseData[]> => {
  const recoverTicketsDaysFrom = getRecoverTicketsDaysFrom();
  const recoverTicketsDaysTo = getRecoverTicketsDaysTo();
  let start = moment().utcOffset(0, true).startOf("day").format();
  if (getSiteId() === '11261') {
    start = moment("2023-10-25 00:00").utcOffset(0, true).startOf("day").format();
  }
  if(recoverTicketsDaysFrom){
    start = moment().add(-recoverTicketsDaysFrom, 'days').utcOffset(0, true).startOf("day").format();
  }
  let end = moment().add(1,'days').utcOffset(0, true).endOf("day").format();
  if(recoverTicketsDaysTo){
    end = moment().add(recoverTicketsDaysTo, 'days').utcOffset(0, true).endOf("day").format();
  }
  let url = `${getSiteUrl()}/events/${eventId}/order?start=${start}&end=${end}`;
  if (lastUpdate) {
    url += `&modified=${moment(lastUpdate).format()}`;
  }
  const tickets = await apiGetTicketsByEventIdPaginated(url);
  const ticketsRes: TicketResponseData[] = [];
  const count = (tickets as TicketCountResponse).count;
  const limit = (getSiteId() === '11261' ? 500 : TICKETS_LIMIT);
  const totalPages = Math.ceil(count / limit);
  for (let i = 0; i < totalPages; i++) {
    const result = await apiGetTicketsByEventIdPaginated(url, false, i * limit, limit);
    await foundPageCallback?.(result as TicketResponseData[]);
    (result as TicketResponseData[]).forEach((ticket) => ticketsRes.push(ticket));
  }
  return ticketsRes;
};

export const apiGetTicketsByEventIdPaginated = async (
  url: string,
  count = true,
  skip = 0,
  take = TICKETS_LIMIT,
): Promise<TicketResponseData[] | TicketCountResponse> => {
  const res = await axios.get<TicketResponse>(`${url}&take=${take}&skip=${skip}&count=${count}`);
  return res.data;
};

export const INTEGRATION_API_FAILS_MSG = 'integration-api fails';

export const apiCheckinTicketIntApi = async (
  barCode: string,
  eventId: string,
  status: 'checked' | 'pending' | 'created' | 'uncheck',
): Promise<CheckinStatus> => {
  const params = {
    barCode,
  };
  try {
    const res = await axios.post<CheckinStatus>(
      `${getIntegrationApiUrl() || ''}/ext/orders-tickets/checkin/${eventId}/${barCode}/${status}`,
      params,
      {
        headers: {
          'client-token': getClientToken(),
        },
      },
    );
    return res.data;
  } catch (error) {
    console.warn('Can not checking into integraiont api', error);
    return {
      success: false,
      errorMessage: INTEGRATION_API_FAILS_MSG,
    };
  }
};

export const apiGetTicktsTypes = async (eventId: string): Promise<TicketTypeResponse> => {
  const res = await axios.get<TicketTypeResponse>(`${getSiteUrl()}/api/v1/events/${eventId}/ticket-types`);
  return res.data;
};
