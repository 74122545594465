import {
  Box,
  CircularProgress,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { TicketCount } from './ticket-count';

const useStyles = makeStyles((theme) => ({
  checkIconSuccess: {
    color: theme.palette.success.main,
  },
  checkIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  checkedInText: {
    textDecoration: 'line-through',
  },
  listItem: {
    padding: '1.25rem 0',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  listIcon: {
    justifyContent: 'flex-end',
    minWidth: 0,
  },
  primaryText: {
    fontWeight: 700,
    marginRight: '0.625rem',
  },
  block: {
    display: 'block',
  },
}));

interface OrderListItemProps {
  checkedIn?: boolean;
  primaryText: string;
  secondaryText?: string;
  additionalInfoText?: string;
  timeSlotDate?: string;
  subtitle?: string;
  handleClick: () => void;
  ticketCount: number;
  icon?: JSX.Element;
}

export const OrderListItem = ({
  checkedIn,
  primaryText,
  secondaryText,
  additionalInfoText,
  timeSlotDate,
  subtitle,
  handleClick,
  ticketCount,
  icon,
}: OrderListItemProps): ReactElement => {
  const classes = useStyles();

  return (
    <ListItem divider className={classes.listItem} onClick={handleClick}>
      <ListItemText
        primary={
          <Box display='flex' alignItems='center'>
            <TicketCount count={ticketCount} />
            <Typography variant='body2' className={classes.primaryText}>
              <span className={checkedIn === true ? classes.checkedInText : ''}>{primaryText}</span>
            </Typography>
            {subtitle !== undefined && (
              <Typography variant='body2' color='textSecondary'>
                - <span className={checkedIn === true ? classes.checkedInText : ''}>{subtitle}</span>
              </Typography>
            )}
          </Box>
        }
        secondary={
          <>
            {timeSlotDate && (
              <Typography component='span' variant='body2' color='textSecondary' className={classes.block}>
                {timeSlotDate}
              </Typography>
            )}
            {secondaryText && (
              <Typography component='span' variant='body2' color='textSecondary' className={classes.block}>
                {secondaryText}
              </Typography>
            )}
            {additionalInfoText && (
              <Typography component='span' variant='body2' color='textSecondary' className={classes.block}>
                {additionalInfoText}
              </Typography>
            )}
          </>
        }
      ></ListItemText>
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
    </ListItem>
  );
};
