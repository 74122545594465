/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useEffect, useState } from 'react';
import { Box, Grid, List, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Order } from '../../model/order';
import { EventCalendar } from '../display/event-calendar';
import { SearchInput } from '../display/search-input';
import { LoaderModal } from '../display/loader-modal';
import { countValidTickets } from '../../util/order-util';
import { OrderListItem } from '../display/order-list-item';
import { fixDateIfApplyAndCleanOffset } from '../../util/event-date-utils';
import { useAppSelector } from '../../store';
import {cleanOrders, setSelectedOrder} from '../../store/slices/orderSlice';
import { getTimeSlotsFromOrder } from '../../util/tickeet-utils';
import { selectEventsToScan, selectIsMultipleEvents, selectSelectedEvent } from '../../store/selectors';
import { BOX_OFFICE_NAME } from '../../model/constants';
import { i18n_t } from '../../translations/translate';
import {findOrders, findOrdersById} from "../../store/thunks/orderThunk";

const useStyles = makeStyles({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  title: {
    lineHeight: '1.3125rem',
  },
  list: {
    margin: '0 1.25rem',
    paddingTop: 0,
    textTransform: 'uppercase',
  },
  marginTop: {
    marginTop: '1.25rem',
  },
});

const ShowProperMessage: FC<{ loading: boolean; isQuery: boolean }> = ({ loading, isQuery }) => {
  if (isQuery) {
    return (
      <Box textAlign='center' pt={2}>
        {!loading && <Typography variant='body1'>{i18n_t('noAttendeesFound')}</Typography>}
      </Box>
    );
  } else {
    return null;
  }
};

export const AttendeesPage: FC = () => {
  const classes = useStyles();
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const eventsToScan = useAppSelector(selectEventsToScan);

  const event = useAppSelector(selectSelectedEvent);
  const { orders, loading } = useAppSelector((state) => state.order);
  const [loadingText, setLoadingText] = useState('');
  const [isQuery, setIsQuery] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const keywords = [i18n_t('name'), i18n_t('orderNumber'), i18n_t('email')];

  const handleSubmit = (searchTerm: string) => {
    setError(null);
    setLoadingText(`Searching attendee data for '${searchTerm}'`);

    const queries = eventsToScan.map((toScan) => ({
      eventId: toScan.event.id,
      searchTerm,
    }));
    dispatch(findOrders(queries));
    setIsQuery(true);
  };

  const goToOrderDetails = (order: Order) => {
    dispatch(setSelectedOrder(order))
    history.push(`/event/${order._eventId || eventId}/order/${order.id}/attendees`, { attendeePage: true });
  };

  useEffect(() => {
    dispatch(cleanOrders());
  }, []);

  if (!event) {
    return null;
  }

  return (
    <>
      <Box padding='1.25rem'>
        <Link to={`/event/${eventId}`}>
          <Grid container alignItems='center'>
            <Grid item xs={1}>
              <ArrowBackIosIcon className={classes.arrowIcon} />
            </Grid>
            <Grid item xs={2}>
              <EventCalendar weekday={false} date={fixDateIfApplyAndCleanOffset(event.eventStartDate)} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant='h6' className={classes.title}>
                {isMultipleEvents ? 'Multiples Events' : event.title}
              </Typography>
              <Typography variant='subtitle2' color='secondary'>
                {i18n_t('attendeeList')}
              </Typography>
            </Grid>
          </Grid>
        </Link>
      </Box>
      <SearchInput placeholder={i18n_t('findAttendees')} keywordOptions={keywords} handleSubmit={handleSubmit} />
      {error !== null && (
        <Typography color='error' align='center' className={classes.marginTop}>
          {error}
        </Typography>
      )}
      {orders !== null && (
        <>
          {orders.length > 0 ? (
            <List className={classes.list}>
              {orders.map((order) => (
                <OrderListItem
                  key={order.id}
                  primaryText={
                    (order?.purchaserInfo?.firstName &&
                      `${order.purchaserInfo.firstName} ${order.purchaserInfo.lastName}`) ||
                    BOX_OFFICE_NAME
                  }
                  subtitle={`#${order.orderIdentifier}`}
                  secondaryText={order.purchaserInfo.purchaserEmailAddress}
                  additionalInfoText={getTimeSlotsFromOrder(order) || ''}
                  ticketCount={countValidTickets(order, false)}
                  handleClick={() => goToOrderDetails(order)}
                  icon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                />
              ))}
            </List>
          ) : (
            <ShowProperMessage loading={loading} isQuery={isQuery} />
          )}
        </>
      )}
      <LoaderModal loading={loading} text={loadingText} />
    </>
  );
};

export default AttendeesPage;
