import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { i18n_t } from '../../translations/translate';

const useStyles = makeStyles(() => ({
  formContainer: {
    backgroundColor: '#ffffff',
    height: '53px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  optionsBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#ffffff',
    height: '100%',
    fontSize: '1.125rem',
  },
  list: {
    marginTop: 0,
    paddingLeft: '2rem',
    lineHeight: '1.8rem',
  },
}));

interface SearchInputProps {
  placeholder: string;
  keywordOptions?: string[];
  handleSubmit?: (keyword: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  handleSubmit = () => null,
  keywordOptions,
}: SearchInputProps) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  const onFormSubmit = (event) => {
    event.preventDefault();
    setShowOptions(false);
    handleSubmit(searchTerm);
  };

  const onInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onClearClick = () => {
    setSearchTerm('');
    setShowOptions(!showOptions);
    handleSubmit('');
  };

  const handleKeyPress = (event) => {
    // for native keyboard return (ios)
    if (event.keyCode === 13) {
      event.preventDefault();
      setShowOptions(false);
      onFormSubmit(event);
    }
  };

  return (
    <>
      <form className={classes.formContainer} id='search-input' onSubmit={onFormSubmit}>
        <TextField
          fullWidth
          value={searchTerm}
          placeholder={placeholder}
          onChange={onInputChange}
          onClick={() => setShowOptions(!showOptions)}
          onKeyDown={handleKeyPress}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={onClearClick}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={onFormSubmit}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
      {showOptions === true && keywordOptions !== undefined && (
        <Box className={classes.optionsBox} padding='2.75rem'>
          {i18n_t('searchtermsMayInclude')}
          <ul className={classes.list}>
            {keywordOptions.map((keyword) => (
              <li key={keyword}>{keyword}</li>
            ))}
          </ul>
        </Box>
      )}
    </>
  );
};
