import React, { useEffect, FC } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store';
import {
  selectEvents, selectEventsLastUpdate,
  selectEventsToScan,
  selectEventsToScanIds,
  selectEventTimeSlotsUpdated, selectLoadingEventDetails,
  selectSettingLoading
} from '../../store/selectors';
import { EventCalendar } from '../display/event-calendar';
import { fixDateIfApplyAndCleanOffset } from '../../util/event-date-utils';
import { EventTitle } from '../display/event-title';
import ScanButtons from '../display/scan-buttons';
import { FONT_BOLD } from '../../model/constants';
import EventTimeSlots from '../display/event-time-slots';
import { useDispatch } from 'react-redux';
import { findMultipleEventsTimeSlots } from "../../store/thunks/eventThunk";
import { existsPendingsWithErrors, setOfflineMode } from "../../store/thunks/settingsThunk";
import { getEventsTimeSlotParams } from "../../util/event-util";
import {cleanLoadingEventDetails, updateCurrentTimeSlots} from "../../store/slices/eventSlice";
import useInternet from "../../hooks/internet-hook";

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  marginTop: {
    marginTop: '1.25rem',
  },
  searchBtn: {
    marginTop: '1.25rem',
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.info.main,
      boxShadow: 'none',
    },
  },
  subtitle: {
    marginTop: '1rem',
    fontSize: '0.9375rem',
  },
  eventList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
    borderBottom: '1px solid rgba(0, 0, 0, .2)',
  },
  loadingIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vh - 100px)',
  },
}));

const MultipleEventsPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const events = useAppSelector(selectEvents);
  const eventsToScan = useAppSelector(selectEventsToScan);
  const eventsToScanIds = useAppSelector(selectEventsToScanIds);
  const loadingSettings = useAppSelector(selectSettingLoading);
  const eventTimeSlotsUpdated = useAppSelector(selectEventTimeSlotsUpdated);
  const eventsLastUpdate = useAppSelector(selectEventsLastUpdate);
  const { hasInternet } = useInternet();

  useEffect(() => {
    const eventsTimeSlotParmas = getEventsTimeSlotParams(events.filter(e => eventsToScanIds.includes(e.id)));
    dispatch(findMultipleEventsTimeSlots({ eventsTimeSlotParmas }));

    dispatch(setOfflineMode(eventsToScanIds.filter((eventId) => !eventsLastUpdate[eventId]) || []));
    dispatch(existsPendingsWithErrors());
  }, []);

  useEffect(() => {
    if (eventTimeSlotsUpdated) {
      dispatch(updateCurrentTimeSlots(hasInternet));
    }
  }, [eventTimeSlotsUpdated]);

  if (loadingSettings) {
    return (
      <Box className={classes.loadingIndicator}>
        <Grid>
          <Grid item xs={12} container alignItems="center" style={{justifyContent:"center"}}>
            <CircularProgress />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' color='textSecondary'>Retrieving event information</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box margin='0 1.125rem' paddingTop='1.5rem'>
      <Box borderBottom='1px solid rgba(0, 0, 0, .26)'>
        <Link to='/'>
          <Grid container alignItems='center'>
            <Grid item xs={1} md={2}>
              <Box>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Box>
            </Grid>
            {eventsToScan[0]?.event.eventStartDate && (
              <Grid item xs={4} md={2}>
                <EventCalendar
                  weekday={true}
                  date={fixDateIfApplyAndCleanOffset(eventsToScan[0]?.event.eventStartDate)}
                />
              </Grid>
            )}
            <Grid item xs={7} md={8}>
              <EventTitle title='Multiple Events' venue={eventsToScan[0]?.event.venue?.name || ''} />
            </Grid>
          </Grid>
        </Link>
        <Divider className={classes.marginTop} />

        <ScanButtons id={'multi-events'} />
      </Box>

      <Box>
        <Button
          variant='contained'
          color='primary'
          size='medium'
          type='submit'
          fullWidth
          className={classes.searchBtn}
          component={Link}
          to={`/attendees/multi-events`}
        >
          View / Search Attendees
        </Button>

        <Typography variant='subtitle2' className={classes.subtitle}>
          Event Time Slots
        </Typography>

        {eventsToScan.map((eventScan) => (
          <EventTimeSlots eventScan={eventScan} key={eventScan.event.id} />
        ))}
      </Box>
    </Box>
  );
};

export default MultipleEventsPage;
