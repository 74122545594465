import React, { FC, MouseEventHandler } from 'react';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../store/slices/userSlice';
import { LocalStorageConf } from '../../model/constants';
import pkg from '../../../package.json';
import { i18n_t } from '../../translations/translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      flexGrow: 1,
      marginBottom: 56,
    },
    themeHeader: {
      backgroundColor: theme.palette.headerBackground,
      color: theme.palette.getContrastText(theme.palette.headerBackground || ''),
    },
    badgeWrapper: {
      marginLeft: theme.spacing(2),
    },
    badge: {
      backgroundColor: theme.palette.success.main,
      borderRadius: 10,
      width: '16px',
      height: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    badgeIcon: {
      width: '13.33px',
      height: '13.33px',
      color: '#ffffff',
    },
    avatar: {
      background: theme.palette.info.main,
      width: 33,
      height: 33,
      border: '1px solid #E0E0E0',
    },
    accountButton: {
      padding: 0,
    },
    logo: {
      maxHeight: 44,
      maxWidth: 160,
    },
  }),
);

export const Header: FC = () => {
  const { userName } = useAppSelector((state) => state.user);
  const { logoUrl } = useAppSelector((state) => state.settings);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleUserMenu: MouseEventHandler = (event) => {
    //@ts-ignore
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(userLogout());
    localStorage.removeItem(LocalStorageConf.Token);
  };

  return (
    <div className={classes.appBar}>
      <AppBar position='fixed' className={classes.themeHeader}>
        <Toolbar>
          <img src={logoUrl} className={classes.logo} alt='logo' />

          <Box display='flex' justifyContent='flex-end' alignItems='center' flexGrow={2}>
            <Typography variant='h5'>{i18n_t('checkIn')}</Typography>

            <div className={classes.badgeWrapper}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                overlap='circular'
                badgeContent={
                  <div className={classes.badge}>
                    <StarIcon fontSize='small' className={classes.badgeIcon} />
                  </div>
                }
              >
                <IconButton onClick={handleUserMenu} className={classes.accountButton}>
                  <Avatar className={classes.avatar}>{userName?.toUpperCase()}</Avatar>
                </IconButton>
              </Badge>
            </div>
          </Box>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={anchorEl !== null}
            onClose={handleClose}
          >
            <MenuItem component={Link} to='/about' onClick={handleClose}>
              <Typography variant='subtitle1'>Enterprise v{pkg.version}</Typography>
            </MenuItem>
            <MenuItem component={Link} onClick={handleLogout} to='/'>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};
