import { db, PendingTicket, PendingTicketState } from '.';
import {TicketDb} from "../../model/tse/ticket";

export class CheckinsService {
  getAllPendingTickets(): Promise<PendingTicket[]> {
    return db.transaction('r', db.pendingCheckin, async () => {
      return await db.pendingCheckin.toArray();
    });
  }

  async existsPendingsWithErrors(): Promise<number> {
    return await db.pendingCheckin.where({ state: PendingTicketState.ERROR }).count();
  }

  getPendingTickets(offset: number, limit: number): Promise<PendingTicket[]> {
    return db.transaction('r', db.pendingCheckin, async () => {
      return await db.pendingCheckin.offset(offset).limit(limit).toArray();
    });
  }

  getPendingTicketsByState(state: PendingTicketState) {
    return db.transaction('r', db.pendingCheckin, async () => {
      return await db.pendingCheckin.where({ state }).toArray();
    });
  }

  updatePendingTicket(pendingTicket: PendingTicket) {
    return db.transaction('rw', db.pendingCheckin, async () => {
      return await db.pendingCheckin.update(pendingTicket.id, pendingTicket);
    });
  }

  deletePendingTicket(id: string): Promise<void> {
    return db.transaction('rw', db.pendingCheckin, async () => {
      return await db.pendingCheckin.delete(id);
    });
  }

  getPendingTicket(barCode: string): Promise<PendingTicket | undefined> {
    return db.transaction('rw', db.pendingCheckin, async () => {
      return await db.pendingCheckin.where({ barCode }).first();
    });
  }

  addPendingCheckin(ticket: PendingTicket): Promise<string> {
    return db.transaction('rw', db.pendingCheckin, async () => {
      return await db.pendingCheckin.add(ticket);
    });
  }
}
