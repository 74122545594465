import { ticketsDBService } from '.';
import {Order, OrderTicket} from '../../model/order';
import {TicketDb, TicketStatus} from '../../model/tse/ticket';

export class OrdersService {
  async getOrderById(orderId: string): Promise<Order> {
    const tickets = await ticketsDBService.getTicketsByOrderId(orderId);
    if(tickets.length === 0){
      return {} as Order;
    }
    const { orderIdentifier, datePurchasedUtc, purchaserEmailAddress, purchaserFirstName, purchaserLastName, eventId } = tickets[0];
    return {
      id: orderId,
      orderIdentifier,
      datePurchasedUtc,
      purchaserInfo: {
        purchaserEmailAddress: purchaserEmailAddress,
        firstName: purchaserFirstName,
        lastName: purchaserLastName,
      },
      lineItems: [{
        tickets: tickets.map(t => this.transformTicketDbToOrderTicket(t)),
      }],
      _eventId: eventId,
    }
  }

  transformTicketDbToOrderTicket = (ticketDb: TicketDb): OrderTicket => {
    const { ticketBarCode, ticketStatus, redemptionInfo, ticketTypeInfo } = ticketDb;
    return {
      id: ticketDb.id,
      redemptionInfo: redemptionInfo,
      ticketBarCode,
      ticketStatus: ticketStatus as TicketStatus,
      ticketTypeInfo: ticketTypeInfo,
    }
  };

  filterArrId = (arr: string[]): string[] =>
    arr.reduce((prev: string[], curr: string) => {
      return prev.indexOf(curr) < 0 ? [...prev, curr] : prev;
    }, []);

  async transformTicketsToOrders(tickets: TicketDb[]): Promise<Order[]> {
    const orderIds = tickets.map((ticket) => ticket.orderId);
    const pendings: Promise<Order>[] = [];
    this.filterArrId(orderIds).forEach((orderId) => pendings.push(this.getOrderById(orderId)));
    return Promise.all(pendings);
  }
}
