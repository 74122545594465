import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { GenericMessage } from '../../model/constants';
import {loadSettings, setOfflineMode} from "../thunks/settingsThunk";

export interface SettingsState {
  headerBackground: string;
  logoUrl: string;
  accentColor: string;
  loading: boolean;
  errorMessage: string | null;
  syncMode: boolean;
  reset: boolean;
}

const initialState: SettingsState = {
  headerBackground: '#000000',
  logoUrl: '',
  accentColor: '#CCaaCC',
  loading: false,
  errorMessage: '',
  syncMode: false,
  reset: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleSyncMode: (state) => {
      state.syncMode = !state.syncMode;
    },
    reduxReset: (state) => {
      state.reset = true;
    },
    setLoadingManually: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettings.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
    });
    builder.addCase(loadSettings.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accentColor = payload.accentColor;
      state.logoUrl = payload.logoUrl;
      state.headerBackground = payload.headerBackground;
    });
    builder.addCase(loadSettings.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(setOfflineMode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setOfflineMode.fulfilled, (state) => {
      state.loading = false;
      state.syncMode = true;
    });
    builder.addCase(setOfflineMode.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
  },
});

export default settingsSlice.reducer;

export const { setLoadingManually, reduxReset, toggleSyncMode } = settingsSlice.actions;
