/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, makeStyles, Modal, Slider, Theme, Typography, withStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { format } from 'date-fns';
import { QRCode } from 'jsqr';
import { Link, useHistory, useParams } from 'react-router-dom';
import {ConfirmationState, FONT_BOLD, OrderMessage} from '../../model/constants';
import { isOrderCheckedIn } from '../../util/order-util';
import { CheckInOverrideModal } from '../display/check-in-override-modal';
import { cleanDeviceOffset, fixDateIfApplyAndCleanOffset } from '../../util/event-date-utils';
import { EventCalendar } from '../display/event-calendar';
import { LoaderModal } from '../display/loader-modal';
import { TicketScanner } from '../display/ticket-scanner';
import { useAppDispatch, useAppSelector } from '../../store';
import { cleanCheckin, setErrorMessage } from '../../store/slices/ticketSlice';
import { PlayFunction } from 'use-sound/dist/types';
import {
  selectCurrentSlot,
  selectEventsToScan, selectEventTimeSlots,
  selectIsMultipleEvents,
  selectOpenModal,
  selectSelectedEvent,
} from '../../store/selectors';
import { getCheckinParams } from '../../util/tickeet-utils';
import { i18n_t } from '../../translations/translate';
import SuccessNotification from '../display/success-notification';
import { TtlArray } from '../../util/ttl-array';
import { openModal } from '../../store/slices/modalSlice';
import ConfirmationModal from '../display/confirmation-modal';
import {checkinTicket, updateOrderAfterCheckin} from "../../store/thunks/ticketThunk";
import {ticketsDBService} from "../../service/db-service";
import InfoIcon from "@material-ui/icons/Info";
import {OrderTicket} from "../../model/order";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginTop: '1.25rem',
    marginBottom: '0.625rem',
    fontFamily: FONT_BOLD,
  },
  openButton: {
    backgroundColor: theme.palette.info.main,
  },
  controlBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#ffffff',
    position: 'absolute',
    padding: '1.25rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
    width: '100%',
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  arrowIconWhite: {
    color: '#ffffff',
    fontSize: '0.9375rem',
  },
  modalBox: {
    width: '100%',
    maxWidth: '25rem',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    'border-radius': '10px',
  },
  modalPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  modalTicket: {
    position: 'absolute',
    width: '100%',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  detailsButton: {
    marginTop: '1.25rem',
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
    background: 'grey'
  },
}));

const ZoomSlider = withStyles({
  root: {
    color: '#0A84FF',
    height: 2,
    margin: '0 0.5rem',
  },
  thumb: {
    width: 28,
    height: 28,
    backgroundColor: '#ffffff',
    marginTop: -14,
    marginLeft: -8,
  },
})(Slider);

interface ScanPageProps {
  playSuccess: PlayFunction;
  playError: PlayFunction;
}

export const ScanPage: FC<ScanPageProps> = ({ playSuccess, playError }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const order = useAppSelector((state) => state.order.selectedOrder);
  const { orders } = useAppSelector((state) => state.order);
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const eventsToScan = useAppSelector(selectEventsToScan);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const { checkin, errorMessage, loading, occurenceEventId, confirmationState } = useAppSelector((state) => state.ticket);
  const [videoHeight, setVideoHeight] = useState<number>(100);
  const [successNotification, setSuccessNotification] = useState<boolean>(false);
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);
  const apiLoading = useRef<boolean>(false);
  const { eventId } = useParams();
  const history = useHistory();
  const currentSlot = useAppSelector(selectCurrentSlot);
  const timeSlot = currentSlot?.recurrenceDateTime ? cleanDeviceOffset(currentSlot?.recurrenceDateTime) : new Date();
  let ttlArray: TtlArray;
  const OpenModal = useAppSelector(selectOpenModal);
  const allTickets = order?.lineItems?.flatMap((lineItems) =>
    lineItems.tickets.filter((ticket) => ticket.ticketStatus !== 'Canceled' && ticket.redemptionInfo.redeemed !== true),
  );
  const scanAll = 'scanAll';
  const [checkInEntireOrder, setCheckInEntireOrder] = useState(false);
  const [playedSuccess, setPlayedSuccess] = useState(false);
  const [lastBarCodeScanned, setLastBarCodeScanned] = useState('');
  const [ticketsToScan, setTTicketsToScan] = useState(0);
  const eventTimeSlots = useAppSelector(selectEventTimeSlots);
  let ticketName = '';

  useEffect(() => {
    ttlArray = new TtlArray(5000, 10);
    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  const confirmCheckinScan = React.useCallback(async () => {
    if (checkin) {
      const { barcode } = checkin;
      if (!!barcode && !loading) {
        const recurrenceDateTime = await getRecurrenceDateTime(barcode);
        const checkinParam = getCheckinParams(eventsToScan, barcode, recurrenceDateTime, eventTimeSlots);
        checkinParam.forceConfirmation = true;
        dispatch(checkinTicket(checkinParam));
      }
      setCheckInEntireOrder(false);
    }
  }, [checkin, eventsToScan]);

  const checkinScan = useCallback(
    async (barCode) => {
      setLastBarCodeScanned('');
      if (barCode.length > 0 && apiLoading.current === false && errorMessage === null) {
        apiLoading.current = true;
        const recurrenceDateTime = await getRecurrenceDateTime(barCode);
        const checkin = getCheckinParams(eventsToScan, barCode, recurrenceDateTime, eventTimeSlots);
        if (barCode?.length > 0 && !loading) {
          setLastBarCodeScanned(barCode);
          await dispatch(checkinTicket(checkin));
        }

        apiLoading.current = false;
      }
      setCheckInEntireOrder(false);
    },
    [eventsToScan],
  );

  const checkinMultipleScan = (barCode: string, occurrence: string) => {
    const checkin = getCheckinParams(eventsToScan, barCode, occurrence, eventTimeSlots);
    checkin.forceConfirmation = true;
    if (barCode?.length > 0 && !loading) {
      dispatch(checkinTicket(checkin));
    }
  };

  const onScan = useCallback(
    (code: QRCode): void => {
      const barCode = code.data;
      const isRecent = ttlArray.existRecentValue(barCode);
      if (barCode.length > 0 && !isRecent) {
        ttlArray.add(barCode);
        checkinScan(barCode);
      }
    },
    [checkinScan],
  );

  useEffect(() => {
    if (checkin?.valid && checkin?.success !== false && (!checkInEntireOrder || !playedSuccess)) {
      playSuccess();
      setPlayedSuccess(true);
      setSuccessNotification(true);
      apiLoading.current = false;
      setTimeout(() => {
        setSuccessNotification(false);
        setPlayedSuccess(false);
      }, 5000);

    }
  }, [checkin]);

  const goToScannedEvent = React.useCallback(async () => {
    if (checkin && !loading) {
      try {
        setIsOrderLoading(true);
        const { orderId } = checkin;
        let eventIdScanned = order && order._eventId;
        if (!eventIdScanned && orders.length) {
          const orderItem = orders.find(o => o.id === orderId);
          eventIdScanned = orderItem && orderItem._eventId;
        }
        await dispatch(updateOrderAfterCheckin(orderId || ''));
        setIsOrderLoading(false);
        history.push(`/event/${eventIdScanned}/order/${orderId || ''}/barcode/${checkin.barcode}`);
      } catch (error) {
        console.error(error);
        setIsOrderLoading(false);
      }
    }
  }, [checkin, loading, occurenceEventId, order]);

  const onMediaError = (mediaError: string | DOMException) => dispatch(setErrorMessage(mediaError?.toString()));

  const handleSlider = (event: any, value: number | number[]) => {
    setVideoHeight((value as number) + 100);
  };

  useEffect(() => {
    if (errorMessage) {
      playError();
    }
  }, [errorMessage]);

  const openCheckAll = () => {
    if(!isOrderCheckedIn(order)) {
     dispatch(openModal('scanAll')) 
    }
  }

  const getRecurrenceDateTime = async (barCode: string): Promise<string> => {
    const ticket = await ticketsDBService.getTicketByBarcode(barCode);
    return !!ticket ? ticket.ticketTypeInfo.event.recurrenceDateTime : '';
  }

  const getTicketsWithSameRecurrence = async (barCode: string): Promise<OrderTicket[]> => {
    const recurrenceDateTime = await getRecurrenceDateTime(barCode);
    return allTickets
      ? allTickets.filter(ticket => ticket.ticketTypeInfo.event.recurrenceDateTime === recurrenceDateTime)
      : [];
  }

  const scanAllTickets = async () => {
    try {
      if (!allTickets) {
        return;
      }
      const ticketsWithSameRecurrence = await getTicketsWithSameRecurrence(lastBarCodeScanned);
      for (const ticket of ticketsWithSameRecurrence) {
        checkinMultipleScan(ticket.ticketBarCode, ticket.ticketTypeInfo.event.recurrenceDateTime);
      }
      setCheckInEntireOrder(true);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    if (!allTickets) {
      return;
    }
    const callMethod = async () => {
      const ticketsWithSameRecurrence = await getTicketsWithSameRecurrence(lastBarCodeScanned);
      setTTicketsToScan(ticketsWithSameRecurrence.length);
    };
    callMethod();
  }, [lastBarCodeScanned, allTickets]);

  if (!selectedEvent) {
    return null;
  }

  if (checkInEntireOrder) {
    ticketName = OrderMessage.EntireOrderChecked;
  }

  const label = checkInEntireOrder ? `${ticketName}` : i18n_t('checkedIn');

  return (
    <>
      <Box height={'calc(100vh - 56px)'} width='100vw' overflow='hidden' display='flex' justifyContent='center'>
        <TicketScanner onMediaError={onMediaError} onScan={onScan} videoHeight={videoHeight ? videoHeight : 100} />

        <Box className={classes.controlBox}>
          <Box width='100%'>
            <Link to={`/event/${eventId}`}>
              <Grid container alignItems='center'>
                <Grid item xs={1}>
                  <ArrowBackIosIcon className={classes.arrowIconWhite} />
                </Grid>
                <Grid item xs={2}>
                  <EventCalendar weekday={false} date={fixDateIfApplyAndCleanOffset(selectedEvent.eventStartDate)} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant='h6'>{isMultipleEvents ? 'Multiple Events' : selectedEvent.title}</Typography>
                  <Typography variant='subtitle2'>
                    {selectedEvent.isRecurringEvent && timeSlot && format(new Date(timeSlot), 'h:mm a')} Check-In
                    {selectedEvent.isRecurringEvent && timeSlot && format(new Date(timeSlot), 'h:mm a')}{' '}
                    {i18n_t('checkIn')}
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Box>
        </Box>
        {!loading && successNotification && confirmationState === ConfirmationState.Ok && (
          <Box bottom='11rem' className={classes.modalTicket}>
            <SuccessNotification
              isLoading={isOrderLoading}
              goToScannedEvent={goToScannedEvent}
              label={label}
            />
          </Box>
        )}

        <Box height='178px' bottom='0' className={classes.controlBox}>
          <Button
            variant='outlined'
            disabled={ticketsToScan && (checkin && ticketsToScan > 0) ? false : true}
            fullWidth
            style={{
              background:checkin && ticketsToScan && ticketsToScan >= 1 ? 'skyblue' : 'transparent',
            }}
            className={classes.detailsButton}
            onClick={openCheckAll}
          >
            <InfoIcon fontSize='small' className={classes.infoIcon} />
            {'Scan All Tickets'}
          </Button>
          <ZoomSlider value={videoHeight - 100} onChange={handleSlider} />
        </Box>
      </Box>
      {OpenModal === scanAll && (
        <ConfirmationModal
          title='Check In All Tickets'
          description={`Do you want to Check In ${ticketsToScan} tickets? `}
          onConfirmCallback={scanAllTickets}
        />
      )}
      <LoaderModal loading={loading} />
      <Modal
        open={errorMessage !== null}
        onClose={() => dispatch(setErrorMessage(null))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={classes.modalPosition} p='3.25rem'>
          <Box className={classes.modalBox} px='1.25rem' py='1.875rem'>
            <Box mb='1.5rem'>
              <Typography>{errorMessage}</Typography>
            </Box>
            <Button
              className={classes.closeButton}
              fullWidth
              onClick={() => dispatch(setErrorMessage(null))}
              variant='contained'
            >
              Dismiss
            </Button>
          </Box>
        </Box>
      </Modal>
      <CheckInOverrideModal checkinScan={confirmCheckinScan} />
    </>
  );
};

export default ScanPage;
