import { RootReducerType } from './rootReducer';
import { createWhitelistFilter, createBlacklistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist-indexeddb-storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from '@reduxjs/toolkit';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

export const setupPersistReducer = (rootReducer: RootReducerType): Reducer => {
  const persistConfig = {
    key: 'root',
    storage: storage('uscan'),
    stateReconciler: autoMergeLevel2,
    transforms: [
      createWhitelistFilter('user', ['isAuthenticated']),
      createBlacklistFilter('settings', ['loading']),
      createBlacklistFilter('order', ['loading']),
      createBlacklistFilter('event', ['loading']),
      createBlacklistFilter('ticket', ['loading']),
      createBlacklistFilter('modalConfirmation', ['loading', 'isOpened', 'args']),
    ],
  };

  //@ts-ignore
  return persistReducer(persistConfig, rootReducer);
};
