import { Ticket, TicketDb, TicketResponseData } from "../model/tse/ticket";

export const transformDataTicket = (ticketResponse: TicketResponseData, ticket: Ticket): TicketDb => {
  const { id, orderIdentifier, purchaserInfo, datePurchasedUtc } = ticketResponse;
  const {
    ticketBarCode,
    ticketStatus,
    ticketTypeInfo,
    redemptionInfo
  } = ticket;
  const { purchaserEmailAddress, firstName, lastName } = purchaserInfo;
  const { event } = ticketTypeInfo;
  const fullName = getAllWords(firstName + ' ' + lastName);
  return {
    id: ticket.id,
    orderId: id,
    orderIdentifier,
    ticketStatus,
    ticketBarCode,
    purchaserEmailAddress: purchaserEmailAddress !== undefined ? purchaserEmailAddress : '',
    purchaserFirstName: firstName !== undefined ? firstName : '',
    purchaserLastName: lastName !== undefined ? lastName : '',
    purchaserFullName: firstName !== undefined && lastName !== undefined ? fullName : [],
    eventId: event.id,
    datePurchasedUtc,
    ticketTypeInfo: {
      tierType: {
        name: ticketTypeInfo.tierType.name,
      },
      ticketType: {
        name: ticketTypeInfo.ticketType.name,
      },
      event: {
        id: ticketTypeInfo.event.id,
        recurrenceDateTime: ticketTypeInfo.event.recurrenceDateTime,
      }
    },
    redemptionInfo: {
      dateRedeemedUtc: redemptionInfo.dateRedeemedUtc,
      redeemedByUserId: redemptionInfo.redeemedByUserId,
      redeemed: redemptionInfo.redeemed,
    },
  }
};

const getAllWords = (text: string) => {
  const allWordsIncludingDups = text.split(' ');
  const wordSet = allWordsIncludingDups.reduce(function (prev, current) {
    prev[current] = true;
    return prev;
  }, {});
  return Object.keys(wordSet);
}