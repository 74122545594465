import {
  EventTimeSlotsResponse,
  EventTimeSlotsParams,
  MultipleEventTimeSlotsParams,
} from './../../model/event';
import { apiFindEvents, apiGetEventTimeSlots } from './../../service/api-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError } from '../../model/api-error';
import { EventQueryRequest, Event as TsEvent, TimeSlotsResponse } from '../../model/event';
import { cleanDeviceOffset, getSortedEvents } from '../../util/event-date-utils';

export const findEvents = createAsyncThunk<TsEvent[], EventQueryRequest, { rejectValue: ApiError }>(
  'loadEvents',
  async (query, thunkApi) => {
    try {
      const res = await apiFindEvents(query);
      return getSortedEvents(res.items);
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const findMultipleEventsTimeSlots = createAsyncThunk<
  EventTimeSlotsResponse[],
  MultipleEventTimeSlotsParams,
  { rejectValue: ApiError }
>('findMultipleEventsTimeSlots', async ({ eventsTimeSlotParmas }, thunkApi) => {
  try {
    const pendings: Promise<EventTimeSlotsResponse>[] = [];

    eventsTimeSlotParmas.forEach((timeSlotParmas) => {
      pendings.push(findEventTimeSlots(timeSlotParmas));
    });

    return await Promise.all(pendings);
  } catch (error) {
    return thunkApi.rejectWithValue(error as ApiError);
  }
});

const findEventTimeSlots = async ({
  query,
}: EventTimeSlotsParams): Promise<EventTimeSlotsResponse> => {
  try {
    const res: TimeSlotsResponse = await apiGetEventTimeSlots(query);

    return {
      eventId: query.eventId,
      items: res.items,
      currentSlot: null,
    };
  } catch (error) {
    return {
      eventId: query.eventId,
      items: null,
      currentSlot: null,
    };
  }
};
