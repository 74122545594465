import {getTodayAsString, getTomorrowAsString} from "./event-date-utils";
import { Event as TsEvent } from '../model/event';

const getEventsTimeSlotParams = (events: TsEvent[]) => {
  return events.map(( event ) => getEventTimeSlotParam(event.id, event.redeemMinutesAfter));
}

const getEventTimeSlotParam = (eventId: string, redeemMinutesAfter?: number | undefined) => {
  return {
    redeemMinutesAfter: redeemMinutesAfter,
    query: {
      eventId,
      fromDate: getTodayAsString(),
      toDate: getTomorrowAsString(),
    },
  }
}

export {
  getEventsTimeSlotParams,
  getEventTimeSlotParam,
};
