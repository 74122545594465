import { TicketDb } from './ticket';

export interface FindOrdersQuery {
  eventId?: string;
  search?: string;
  skip?: number;
  take?: number;
}

export interface Order {
  id: string;
  created: string;
  hash: string;
  tickets: TicketDb[];
}

export enum ProcessState {
  Purchased = '0',
  CheckedIn = '2',
  Cancelled = '4',
}

export enum TicketState {
  Active = 'Active',
  Redeemed = 'Redeemed',
  Canceled = 'Canceled',
}
