interface TtlItem {
  value: string;
  timestamp: number;
}

export class TtlArray {
  data: TtlItem[];
  size?: number;
  constructor(private ttl: number, maxSize?: number) {
    this.data = [];
    this.size = maxSize;
  }

  add(value: string): void {
    const it = { value, timestamp: Date.now() };
    this.data.push(it);
    if (this.size && this.data.length > this.size) {
      this.data = this.data.slice(-this.size);
    }
  }

  getValidItems(): TtlItem[] {
    return this.data.filter((d) => Date.now() - d.timestamp <= this.ttl);
  }

  getValidArray(): string[] {
    return this.getValidItems().map((i) => i.value);
  }

  clean(): void {
    this.data = this.getValidItems();
  }

  existRecentValue(value: string): boolean {
    return !!this.getValidItems().find((d) => d.value === value);
  }

  getValue(value: string): string | undefined {
    return this.getValidItems().find((d) => d.value === value)?.value;
  }
}
