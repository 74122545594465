import { endOfDay, format, isBefore, isToday } from 'date-fns';
import { Event } from '../model/event';

export const lessOrEqualsThanToday = (date: Date): boolean => {
  return isToday(date) || isBefore(date, getTodayAtEndOfDay());
};

export const getTodayAtEndOfDay = (): Date => {
  return endOfDay(new Date());
};

export const fixDateIfApply = (date: Date): Date => {
  return lessOrEqualsThanToday(date) ? getTodayAtEndOfDay() : date;
};

export const fixDateIfApplyAndCleanOffset = (dateStr: string): Date => {
  const date = new Date(dateStr);
  return lessOrEqualsThanToday(date) ? getTodayAtEndOfDay() : cleanDateOffset(date);
};

export const getTodayAsString = (): string => format(new Date(), 'yyyy-M-d');

export const getTomorrowAsString = (): string => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  return format(today, 'yyyy-M-d');
}

export const cleanDeviceOffset = (dateStr?: string): Date => {
  const dt = dateStr ? new Date(dateStr) : new Date();
  return cleanDateOffset(dt);
};

export const cleanDateOffset = (date: Date): Date => {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const getSortedEvents = (events: Event[]): Event[] => {
  try {
    return events
      .map((e: Event) => e)
      .sort((a: Event, b: Event) =>
        a.eventStartDate > b.eventStartDate ? 1 : b.eventStartDate > a.eventStartDate ? -1 : 0,
      );
  } catch (error) {
    return events;
  }
};
