import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AttendeesPage from './page/attendees-page';
import EventDetailPage from './page/event-detail-page';
import LandingPage from './page/landing-page';
import LaserScanPage from './page/laser-scan-page';
import { LoginPage } from './page/login-page';
import { NotFoundPage } from './page/not-found-page';
import OrderDetailPage from './page/order-detail-page';
import ScanPage from './page/scan-page';
import { AuthRoute } from './widget/auth-route';
import Layout from './widget/layout';
import useSound from 'use-sound';
import successWav from '../assets/sounds/success2.wav';
import errorWav from '../assets/sounds/error2.wav';
import MultipleEventsPage from './page/multiple-events-page';
import SyncMode from "./sync-mode";
import {AboutPage} from "./page/settings-page";
import {CheckinSyncPage} from "./page/synchronization/synchronization-page";

export const Routes: FC = () => {
  const [playSuccess] = useSound(successWav);
  const [playError] = useSound(errorWav);

  return (
    <BrowserRouter>
      <SyncMode>
        <Layout>
          <Switch>
            <AuthRoute exact path='/' child={<LandingPage />} />
            <Route path='/login'>
              <LoginPage />
            </Route>
            <AuthRoute exact path='/event/multi-events' child={<MultipleEventsPage />} />
            <AuthRoute exact path='/event/:id' child={<EventDetailPage />} />
            <AuthRoute path='/attendees/:eventId' child={<AttendeesPage />} />
            <AuthRoute path='/event/:eventId/order/:orderId/attendees' child={<OrderDetailPage />} />
            <AuthRoute path='/event/:eventId/order/:orderId/barcode/:barCode' child={<OrderDetailPage />} />
            <AuthRoute path='/scan/:eventId' child={<ScanPage playError={playError} playSuccess={playSuccess} />} />
            <AuthRoute path='/about' child={<AboutPage />} />
            <AuthRoute path='/checkin-sync' child={<CheckinSyncPage />} />
            <AuthRoute
              path='/laser-scan/:eventId'
              child={<LaserScanPage playError={playError} playSuccess={playSuccess} />}
            />
            <Route path='*'>
              <NotFoundPage />
            </Route>
          </Switch>
        </Layout>
      </SyncMode>
    </BrowserRouter>
  );
};

export default Routes;
