import React, { FC, useEffect, useRef } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ReactComponent as LaserScanIcon } from '../../assets/images/laser-scan-icon.svg';

const useStyles = makeStyles({
  enterButtonStyle: {
    marginTop: '12px',
    backgroundColor: '#000',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1F3C8B',
    },
  },
  barcodeInputStyle: {
    width: '100%',
    height: '40px',
    marginLeft: '8px',
    fontSize: 16,
    display: 'flex',
    textAlign: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
    '&:focus': {
      'box-shadow': '0 0 5px #64e469',
      border: '1px solid #64e469',
    },
  },
});

interface KeyboardReaderProps {
  onKeyboardReaderEnter?: (inputValue: string) => void;
}

export const KeyboardReader: FC<KeyboardReaderProps> = ({ onKeyboardReaderEnter }) => {
  const { barcodeInputStyle, enterButtonStyle } = useStyles();
  const myRef = useRef(null);
  useEffect(() => {
    const setFocus = () => {
      if (myRef.current !== null) {
        const textInput = myRef.current as HTMLElement;
        textInput.focus();

        const code = textInput.textContent;
        if (code && code.length >= 12) {
          handleSendCode(code);
          textInput.innerHTML = '';
        }
      }
    };
    setFocus();
    setInterval(setFocus, 500);
  }, []);

  const handleSendCode = (barcode?: string) => {
    let code = barcode;
    if (!barcode && myRef.current != null) {
      const textInput = myRef.current as HTMLElement;
      if (textInput.textContent) {
        code = textInput.textContent;
      }
    }
    if (code && onKeyboardReaderEnter) {
      onKeyboardReaderEnter(code);
    }
  };

  return (
    <>
      <Box display={'flex'} mt={'1rem'} alignItems='center'>
        <LaserScanIcon />
        <Box
          {...({ ref: myRef } as any)}
          contentEditable
          virtualkeyboardpolicy='manual'
          id='scan-editor'
          className={barcodeInputStyle}
        ></Box>
      </Box>
      <Button
        variant='contained'
        size='medium'
        type='submit'
        color='primary'
        fullWidth
        className={enterButtonStyle}
        onClick={() => handleSendCode()}
      >
        Enter
      </Button>
    </>
  );
};
