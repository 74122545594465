import { Box, Checkbox, Grid, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { FC } from 'react';
import { Event } from '../../model/event';
import { EventCalendar } from './event-calendar';
import { EventTitle } from './event-title';
import OfflinePinOutlinedIcon from '@material-ui/icons/OfflinePinOutlined';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    background: theme.palette.warning.main,
    padding: 0,
    textAlign: 'center',
  },
  cardContent: {
    padding: 0,
    textAlign: 'center',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  offline: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

interface EventRowProps {
  event: Event;
  weekday: boolean;
  displayDate: Date;
  isMultipleEvents: boolean;
  isSelected?: boolean;
  isRetrieved: boolean;
}

export const EventRow: FC<EventRowProps> = ({
  event,
  weekday,
  displayDate,
  isMultipleEvents,
  isRetrieved,
  isSelected = false
}) => {
  const classes = useStyles();

  return (
    <Box marginBottom='1.25rem'>
      <Grid container alignItems='center'>
        <Grid item xs={3} md={2}>
          <EventCalendar weekday={weekday} date={displayDate} />
        </Grid>
        <Grid item xs={7} md={8}>
          <EventTitle title={event?.title} venue={event?.venue?.name} />
        </Grid>
        <Grid item xs={1} md={1}>
          {isRetrieved && (
            <Box textAlign='right'>
              <OfflinePinOutlinedIcon titleAccess="Content stored for offline mode" className={classes.offline} />
            </Box>
          )}
        </Grid>
        <Grid item xs={1} md={1}>
          {!isMultipleEvents && (
            <Box textAlign='right'>
              <ArrowForwardIosIcon className={classes.arrowIcon} />
            </Box>
          )}
          {isMultipleEvents && (
            <Box textAlign='right'>
              <Checkbox checked={isSelected} name='checkedB' color='primary' />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
