import React, { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store';
import {syncProcess} from "../store/thunks/ticketThunk";

const SYNC_TIME_MS = 30000;

const SyncMode: FC = ({ children }) => {
  const { syncMode } = useAppSelector((state) => state.settings);
  const dispatch = useDispatch();
  type interType = ReturnType<typeof setInterval>;
  const intervalRef = useRef<interType>();

  const sync = React.useCallback(() => {
    if (syncMode) {
      intervalRef.current = setInterval(() => {
        dispatch(syncProcess());
      }, SYNC_TIME_MS);
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [syncMode]);

  useEffect(() => {
    sync();
  }, [syncMode]);

  return <>{children}</>;
};

export default SyncMode;
