/* eslint-disable prefer-const */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useEffect, useState } from 'react';
import { Box, Divider, Grid, makeStyles, Typography, Theme, Button } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { KeyboardReader } from '../display/keyboard-reader';
import { LoaderModal } from '../display/loader-modal';
import { EventCalendar } from '../display/event-calendar';
import { ReactComponent as SuccessWhiteIcon} from '../../assets/images/success-white.svg';
import { ReactComponent as ArrowRightWhiteIcon } from '../../assets/images/arrow-right-white.svg';
import { CheckInOverrideModal } from '../display/check-in-override-modal';
import { isOrderCheckedIn } from '../../util/order-util';
import { format } from 'date-fns';
import { cleanDeviceOffset, fixDateIfApplyAndCleanOffset } from '../../util/event-date-utils';
import { useAppDispatch, useAppSelector } from '../../store';
import { cleanCheckin } from '../../store/slices/ticketSlice';
import { PlayFunction } from 'use-sound/dist/types';
import {
  selectCurrentSlot,
  selectEventsToScan,
  selectEventTimeSlots,
  selectIsMultipleEvents,
  selectOpenModal,
  selectSelectedEvent,
  selectTicketLoading,
  selectTicketScanning,
} from '../../store/selectors';
import { getCheckinParams } from '../../util/tickeet-utils';
import { ConfirmationState, FONT_BOLD, OrderMessage } from '../../model/constants';
import { i18n_t } from '../../translations/translate';
import ConfirmationModal from '../display/confirmation-modal';
import { openModal } from '../../store/slices/modalSlice';
import { checkinTicket, updateOrderAfterCheckin } from "../../store/thunks/ticketThunk";
import { ticketsDBService } from "../../service/db-service";
import InfoIcon from "@material-ui/icons/Info";
import { OrderTicket } from "../../model/order";

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  readyScanContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  errorContainer: {
    paddingTop: '0px',
    fontSize: '15px',
  },
  successContainer: {
    width: '100%',
    padding: '5px 8px',
    borderRadius: '3px',
    backgroundColor: theme.palette.success.main,
    marginTop: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ffffff',
    boxShadow: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  successIcon: {
    marginRight: '1.5rem',
    marginTop: '2px',
  },
  detailsContainer: {
    marginLeft: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  checkinText: {
    fontSize: '15px',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  detailsButton: {
    marginTop: '1.25rem',
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
  },
  detailsText: {
    marginTop: '2px',
  },
}));

interface ScanPageProps {
  playSuccess: PlayFunction;
  playError: PlayFunction;
}

export const LaserScanPage: FC<ScanPageProps> = ({ playSuccess, playError }) => {
  const classes = useStyles();
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const order = useAppSelector((state) => state.order.selectedOrder);
  const { orders } = useAppSelector((state) => state.order);
  const event = useAppSelector(selectSelectedEvent);
  const eventsToScan = useAppSelector(selectEventsToScan);
  const eventTimeSlots = useAppSelector(selectEventTimeSlots);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const currentSlot = useAppSelector(selectCurrentSlot);
  const ticketScanning = useAppSelector(selectTicketScanning);
  const ticketLoading = useAppSelector(selectTicketLoading);
  const OpenModal = useAppSelector(selectOpenModal);
  const scanAll = 'scanAll';
  const allTickets = order?.lineItems?.flatMap((lineItems) =>
    lineItems.tickets.filter((ticket) => ticket.ticketStatus !== 'Canceled' && ticket.redemptionInfo.redeemed !== true),
  );
  const [checkInEntireOrder, setCheckInEntireOrder] = useState(false);
  const [playedSuccess, setPlayedSuccess] = useState(false);
  const [lastBarCodeScanned, setLastBarCodeScanned] = useState('');
  const [ticketsToScan, setTTicketsToScan] = useState(0);

  let ticketName = '';

  const { loading, errorMessage, checkin, confirmationState } = useAppSelector(
    (state) => state.ticket,
  );

  useEffect(() => {
    dispatch(cleanCheckin());

    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  useEffect(() => {
    if (checkin?.valid && (!checkInEntireOrder || !playedSuccess)) {
      playSuccess();
      setPlayedSuccess(true);
    }
  }, [checkin]);

  const timeSlot = currentSlot?.recurrenceDateTime ? cleanDeviceOffset(currentSlot?.recurrenceDateTime) : new Date();

  const confirmCheckinScan = React.useCallback(async () => {
    if (checkin) {
      const { barcode } = checkin;
      if (!!barcode && !loading) {
        const recurrenceDateTime = await getRecurrenceDateTime(barcode);
        const checkinParam = getCheckinParams(eventsToScan, barcode, recurrenceDateTime, eventTimeSlots);
        checkinParam.forceConfirmation = true;
        dispatch(checkinTicket(checkinParam));
      }
      setCheckInEntireOrder(false);
    }
  }, [checkin]);

  const checkinScan = React.useCallback(async (barCode: string) => {
    setLastBarCodeScanned('');
    const recurrenceDateTime = await getRecurrenceDateTime(barCode);
    const checkin = getCheckinParams(eventsToScan, barCode, recurrenceDateTime, eventTimeSlots);
    if (barCode?.length > 0 && !loading) {
      setLastBarCodeScanned(barCode);
      dispatch(checkinTicket(checkin));
    }
    setCheckInEntireOrder(false);
  }, [eventsToScan, dispatch]);

  const getRecurrenceDateTime = async (barCode: string): Promise<string> => {
    const ticket = await ticketsDBService.getTicketByBarcode(barCode);
    return !!ticket ? ticket.ticketTypeInfo.event.recurrenceDateTime : '';
  }

  const getTicketsWithSameRecurrence = async (barCode: string): Promise<OrderTicket[]> => {
    const recurrenceDateTime = await getRecurrenceDateTime(barCode);
    return allTickets
            ? allTickets.filter(ticket => ticket.ticketTypeInfo.event.recurrenceDateTime === recurrenceDateTime)
            : [];
  }

  const checkinMultipleScan = (barCode: string, occurrence: string) => {
    const checkin = getCheckinParams(eventsToScan, barCode, occurrence, eventTimeSlots);
    checkin.forceConfirmation = true;
    if (barCode?.length > 0 && !loading) {
      dispatch(checkinTicket(checkin));
    }
  };

  const goToScannedEvent = React.useCallback(async () => {
    if (checkin && !ticketLoading) {
      try {
        const { orderId, barcode } = checkin;
        let eventIdScanned = order && order._eventId;
        if (!eventIdScanned && orders.length) {
          const orderItem = orders.find(o => o.id === orderId);
          eventIdScanned = orderItem && orderItem._eventId;
        }
        await dispatch(updateOrderAfterCheckin(orderId || ''));
        history.push(`/event/${eventIdScanned}/order/${orderId}/barcode/${barcode}`);
      } catch (error) {
        console.error(error);
      }
    }
  }, [checkin, ticketLoading, order, orders]);

  // let messageToShow: string | null = null;

  // switch (errorMessage) {
  //   case 'Unknown Error':
  //     messageToShow = i18n_t('unknowEerror');
  //     break;
  //   case 'TKT-00007 - This ticket cannot be scanned because it does not match the Event it is being scanned for.':
  //     messageToShow = i18n_t('ticketCanNotBeScanned');
  //     break;
  //   case 'TKT-00005 - This ticket has already been redeemed for this Event occurrence.':
  //     messageToShow = i18n_t('ticketAlreadyRedeemed');
  //     break;
  //   case 'TKT-00014 - This ticket cannot be processed as it has been canceled.':
  //     messageToShow = i18n_t('ticketCanNotBeProcessed');
  //     break;
  //   default:
  //     messageToShow = errorMessage;
  // }

  useEffect(() => {
    if (errorMessage) {
      playError();
    }
  }, [errorMessage]);

  const openCheckAll = () => {
    if (!isOrderCheckedIn(order)) {
      dispatch(openModal('scanAll'));
    }
  };

  const scanAllTickets = async () => {
    try {
      if (!allTickets) {
        return;
      }
      const ticketsWithSameRecurrence = await getTicketsWithSameRecurrence(lastBarCodeScanned);
      for (const ticket of ticketsWithSameRecurrence) {
        checkinMultipleScan(ticket.ticketBarCode, ticket.ticketTypeInfo.event.recurrenceDateTime);
      }
      setCheckInEntireOrder(true);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    if (!allTickets) {
      return;
    }
    const callMethod = async () => {
      const ticketsWithSameRecurrence = await getTicketsWithSameRecurrence(lastBarCodeScanned);
      setTTicketsToScan(ticketsWithSameRecurrence.length);
    };
    callMethod();
  }, [lastBarCodeScanned, allTickets]);

  if (checkInEntireOrder) {
    ticketName = OrderMessage.EntireOrderChecked;
  }

  const label = checkInEntireOrder ? `${ticketName}` : i18n_t('checkedIn');
  
  return (
    <Box paddingTop='1.5rem' margin='0 1.125rem'>
      {event && (
        <Box width='100%' paddingBottom='1.5rem'>
          <Link to={`/event/${eventId}`}>
            <Grid container alignItems='center'>
              <Grid item xs={1}>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Grid>
              <Grid item xs={2}>
                <EventCalendar weekday={false} date={fixDateIfApplyAndCleanOffset(event.eventStartDate)} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant='h6'>{isMultipleEvents ? 'Multiple Events' : event.title}</Typography>
                <Typography variant='subtitle2'>
                  {event.isRecurringEvent && timeSlot && format(new Date(timeSlot), 'h:mm a')} {i18n_t('checkIn')} |{' '}
                  {i18n_t('readyToScan')}
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Box>
      )}
      <Divider />

      <Box display='flex' paddingTop='2rem' textAlign='center' justifyContent='center' flexDirection='column'>
        <Box height='80px'>
          {!loading && checkin && confirmationState === ConfirmationState.Ok && (
            <Box className={classes.successContainer}>
              <Box className={classes.successIcon}>
                <SuccessWhiteIcon />
              </Box>
              <Typography variant='body2' className={classes.checkinText}>
                {ticketScanning?.barCode} {label}
              </Typography>
              <Box onClick={goToScannedEvent} className={classes.detailsContainer}>
                <Typography className='detailsText'>{i18n_t('details')}</Typography>
                <ArrowRightWhiteIcon />
              </Box>
            </Box>
          )}
          {errorMessage && (
            <Typography color='error' className={classes.errorContainer}>
              {errorMessage}
            </Typography>
          )}
        </Box>

        <Box mb={'1rem'}>
          <Button
            variant='outlined'
            disabled={ticketsToScan && ticketsToScan >= 1 && checkin ? false : true}
            style={{
              background:checkin && ticketsToScan && ticketsToScan >= 1 ? 'skyblue' : 'transparent',
            }}
            fullWidth
            className={classes.detailsButton}
            onClick={openCheckAll}
          >
            <InfoIcon fontSize='small' className={classes.infoIcon} />
            {'Scan All Tickets'}
          </Button>
        </Box>
        <KeyboardReader onKeyboardReaderEnter={(value) => checkinScan(value)} />
      </Box>
      {OpenModal === scanAll && (
        <ConfirmationModal
          title='Check In All Tickets'
          description={`Do you want to Check In ${ticketsToScan} tickets? `}
          onConfirmCallback={scanAllTickets}
        />
      )}
      <LoaderModal loading={loading || ticketLoading} />
      <CheckInOverrideModal checkinScan={confirmCheckinScan} />
    </Box>
  );
};

export default LaserScanPage;
