import { getLang, getSiteId } from '../service/api-service';
import { translationEn } from './en';
import { translationJp } from './jp';

export interface TranlationKeys {
  login: string;
  logout: string;
  universal: string;
  checkIn: string;
  username: string;
  Password: string;
  siteID: string;
  authenticationFailed: string;
  findEvents: string;
  today: string;
  upcoming: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  viewSearchAttendees: string;
  beginCameraScanning: string;
  beginLaserScanning: string;
  findAttendees: string;
  searchtermsMayInclude: string;
  name: string;
  orderNumber: string;
  email: string;
  attendeeList: string;
  noAttendeesFound: string;
  readyToScan: string;
  barcode: string;
  enter: string;
  checkedIn: string;
  details: string;
  ticketCanNotBeProcessed: string;
  ticketAlreadyRedeemed: string;
  ticketCanNotBeScanned: string;
  EventOrBarCodeDoesntexist: string;
  unknowEerror: string;
  purchased: string;
  purchaser: string;
  hideOrderDetails: string;
  showOrderDetails: string;
  closeScanner: string;
  continueScanning: string;
}
type Key = keyof TranlationKeys;

export const i18n_t = (key: Key): string => {
  //if ((getLang() && getLang() === 'jp') || window.location.href.indexOf('japan') > 0) {
  // return translationJp[key];
  // }
  return translationEn[key];
};
