import React, { FC } from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as TicketIcon} from '../../assets/images/ticket-icon.svg';
import { ReactComponent as LaserScanIcon} from '../../assets/images/laser-scan-icon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  checkinContainer: {
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  checkinBtn: {
    textTransform: 'uppercase',
    color: '#ffffff',
    backgroundColor: theme.palette.success.main,
    fontSize: '12px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));

interface ScanButtonsProps {
  id: string;
}

const ScanButtons: FC<ScanButtonsProps> = ({ id }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box
          className={classes.checkinContainer}
          textAlign='center'
          padding='0.625rem 0'
          paddingBottom='0'
          margin='1.25rem 0'
        >
          <Link to={`/scan/${id}`}>
            <TicketIcon />
            <Box className={classes.checkinBtn} marginTop='0.625rem' padding='0.5rem 0'>
              <Typography variant='subtitle1'>Begin Camera Scanning</Typography>
            </Box>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          className={classes.checkinContainer}
          textAlign='center'
          padding='0.625rem 0'
          paddingBottom='0'
          margin='1.25rem 0'
        >
          <Link to={`/laser-scan/${id}`}>
            <LaserScanIcon />
            <Box className={classes.checkinBtn} marginTop='0.625rem' padding='0.5rem 0'>
              <Typography variant='subtitle1'>Begin Laser Scanning</Typography>
            </Box>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScanButtons;
