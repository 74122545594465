export const BOX_OFFICE_NAME = 'Box Office';

export const PERSISTED_STORAGE_NAMESPACE = 'mern';

export const ROLE_ADMIN = 'admin';

export const ROLE_USER = 'user';

export const FONT_BOOK = 'Gotham Book, Arial, Helvetica, sans-serif';

export const FONT_BOLD = 'Gotham Bold, Arial, Helvetica, sans-serif';

export const MAX_TICKETS_ON_WHITELIST = 3;

export enum LocalStorageConf {
  SiteId = 'siteId',
  Token = 'token',
  Timeout = 'timeout',
  Lang = 'lang',
  RecoverTicketsDaysFrom = 'recoverTicketsDaysFrom',
  RecoverTicketsDaysTo = 'recoverTicketsDaysTo',
}

export const TIEMOUT_API = '3000';

export enum UserMessages {
  GenericLoginError = 'Authentication Failed',
}
export enum TicketMessages {
  AlreadyScannedError = 'Ticket already scanned',
  AnotherEventError = 'Ticket is for another event',
  AnotherTimeSlotError = 'Ticket is for another time slot',
  DateEventRuleError = 'It is too late for checkin',
  TicketByBarcodeError = 'Error getting ticket by barcode',
  UnknownError = 'Unknown Error',
  TicketNotFound = 'Ticket not found',
}

export enum ConfirmReasonCode {
  AnotherTimeSlot = 'AnotherTimeSlot',
  DateEventRule = 'DateEventRule',
}

export enum GenericMessage {
  UnknownError = 'Unknown Error',
}

export const TICKETS_LIMIT = 300;
export const PENDING_TICKETS_LIMIT = 20;

export enum OrderMessage {
  EntireOrderChecked = 'Order successfully scanned',
}

export enum ConfirmationState {
  Ok,
  Cancel,
  Open,
}
