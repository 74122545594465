import { closestTo, format, getHours, getMinutes } from 'date-fns';
import { Order, OrderResponse, OrderTicket } from '../model/order';
import { Event } from '../model/event';
import { OrderOptimized } from '../model/orderOptimized';
import {CheckinEvent} from "../model/checkin";

const countValidTickets = (order: Order, checkRedeemed: boolean): number => {
  let unredeemedTickets: boolean[] = [];
  order.lineItems.forEach((item: any) => {
    unredeemedTickets = [
      ...unredeemedTickets,
      ...item.tickets.filter((ticket) => {
        if (checkRedeemed === true) {
          return ticket.redemptionInfo.redeemed === false && ticket.ticketStatus !== 'Canceled';
        } else {
          return ticket.ticketStatus !== 'Canceled';
        }
      }),
    ];
  });

  return unredeemedTickets.length;
};

const isOrderCheckedIn = (order): boolean => countValidTickets(order, true) === 0;

const padTime = (num): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

const formatTimeSlot = (timeSlot: Date): string => {
  // This is to accommodate the API where UTC formatted times are actually in local time
  const minutes = padTime(getMinutes(timeSlot));
  const hours = padTime(getHours(timeSlot));

  return `${format(timeSlot, 'yyyy-MM-dd')}T${hours}:${minutes}:00Z`;
};

const getNextTimeSlot = (event: Event | null): string => {
  if (!event) {
    return '';
  }
  // TODO: this logic will need to be updated for recurring events where the time slots change on different days
  const dateString = new Date().toDateString();
  let today = new Date();
  if (event.redeemMinutesAfter > 0) {
    today = new Date(today.getTime() - event.redeemMinutesAfter * 60000);
  }
  // @ts-ignore
  const timeSlots = event.recurrenceInfo.eventTimeSlots.map((time) => new Date(dateString + ' ' + time.timeSlot));

  const nextTimeSlots = timeSlots.filter((time) => time >= today);
  let timeSlot;

  if (nextTimeSlots.length) {
    timeSlot = closestTo(today, nextTimeSlots);
  } else {
    timeSlot = closestTo(today, timeSlots);
  }

  return timeSlot.toISOString();
};

const isRecurringEvent = (event: Event | CheckinEvent | null): boolean => event?.isRecurringEvent === true;

// given the status code, return the status name
const getStatus = (statusCode: number): string => {
  switch (statusCode) {
    case 1:
      return 'Active';
    case 2:
      return 'Canceled';
    case 3:
      return 'Redeemed';
    default:
      return 'Unknown';
  }
};

const transformOrder = (order: OrderOptimized): Order => {
  return {
    id: order.id,
    orderIdentifier: order.orderIdentifier,
    purchaserInfo: {
      purchaserEmailAddress: '',
      firstName: order.purchaserInfo.firstName,
      lastName: order.purchaserInfo.lastName,
    },
    lineItems: [
      {
        tickets: order.tickets.map(
          (ticket) =>
            ({
              redemptionInfo: {
                redeemedByUserId: ticket.redemptionInfo.redeemedByUserId,
                redeemed: ticket.redemptionInfo.redeemed,
              },
              ticketBarCode: ticket.barCode,
              ticketStatus: getStatus(ticket.status),
              ticketTypeInfo: {
                tierType: {
                  name: ticket.ticketInfo.tierType.name,
                },
                event: {
                  recurrenceDateTime: ticket.ticketInfo.event.recurrenceDateTime,
                },
              },
            } as OrderTicket),
        ),
      },
    ],
  };
};

const findFirstOrderId = (response: OrderResponse[]): string | null => {
  for (const res of response) {
    if (res.items?.length > 0) {
      return res.items[0].id;
    }
  }
  return null;
};

export {
  countValidTickets,
  isOrderCheckedIn,
  getNextTimeSlot,
  formatTimeSlot,
  isRecurringEvent,
  padTime,
  transformOrder,
  findFirstOrderId,
};
