import {
  Box,
  Button,
  FilledInputProps,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { createFormReference } from '../../util/form-reference';
import { FONT_BOLD } from '../../model/constants';
import { i18n_t } from '../../translations/translate';

const useStyles = makeStyles({
  textField: {
    border: '1px solid #1F3C8B',
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  buttonText: {
    fontFamily: FONT_BOLD,
    fontSize: '0.875rem',
  },
  button: {
    backgroundColor: '#1F3C8B',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1F3C8B',
    },
  },
  icon: {
    fill: 'rgba(0, 0, 0, 0.54)',
  },
});

export const LoginForm = ({ login }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(null);

  const classes = useStyles();

  const onSubmit = (data) => {
    setError(null);
    login(data.username, data.password, data.siteId);
  };

  return (
    <form id='login-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='login-form'>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              type='text'
              size='small'
              variant='filled'
              label='Username'
              fullWidth
              InputProps={
                {
                  classes: {
                    root: classes.textField,
                  },
                  disableUnderline: true,
                  required: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Person className={classes.icon} />
                    </InputAdornment>
                  ),
                } as Partial<FilledInputProps>
              }
              {...createFormReference('username', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='password'
              size='small'
              variant='filled'
              label='Password'
              fullWidth
              InputProps={
                {
                  classes: {
                    root: classes.textField,
                  },
                  disableUnderline: true,
                  required: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Lock className={classes.icon} />
                    </InputAdornment>
                  ),
                } as Partial<FilledInputProps>
              }
              {...createFormReference('password', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              variant='filled'
              label='Site ID'
              fullWidth
              InputProps={
                {
                  classes: {
                    root: classes.textField,
                  },
                  disableUnderline: true,
                  required: true,
                } as Partial<FilledInputProps>
              }
              {...createFormReference('siteId', register, errors)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              size='medium'
              type='submit'
              color='primary'
              fullWidth
              className={classes.button}
            >
              <Typography className={classes.buttonText}>{i18n_t('login')}</Typography>
            </Button>
            {error !== null ? (
              <Box marginTop={2}>
                <Typography color='error'>{error}</Typography>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
