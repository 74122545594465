import React, { FC } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { FONT_BOOK } from '../../model/constants';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontFamily: FONT_BOOK,
    fontSize: '12px',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.main,
  },
}));

interface EventTitleProps {
  title: string;
  venue: string;
  additionalInfo?: string;
}

export const EventTitle: FC<EventTitleProps> = ({ title, venue, additionalInfo }) => {
  const style = useStyles();
  return (
    <>
      <Typography variant='h6'>{title}</Typography>
      <Typography variant='subtitle2' color='secondary'>
        {venue}
      </Typography>
      {additionalInfo && (
        <Box className={style.subtitle} color='secondary'>
          {additionalInfo}
        </Box>
      )}
    </>
  );
};
