// @ts-nocheck
import jsQR, { QRCode } from 'jsqr';
import React, { useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';

interface TicketScannerProps {
  onScan: (code: QRCode) => void;
  onMediaError?: (error: string | DOMException) => void;
  videoHeight: number;
}

export const TicketScanner: React.FC<TicketScannerProps> = ({ onScan, onMediaError, videoHeight }) => {
  const webcamRef = useRef(null);
  const videoConstraints = {
    // width: 200, height: 200,
    facingMode: 'environment',
  };

  const scanImage = useCallback(() => {
    const webcam = webcamRef.current;
    if (webcam !== null) {
      webcam.getScreenshot({ width: 200, height: 200 });
      const canvas = webcam.canvas;
      if (canvas !== null) {
        const context = webcam.ctx;
        const width = canvas.width;
        const height = canvas.height;
        const imageData = context.getImageData(0, 0, width, height);
        const code = jsQR(imageData.data, width, height);
        if (code !== null) {
          onScan(code);
        }
      }
    }
  }, [onScan]);

  const requestRef = useRef<number>();

  const animate = useCallback(() => {
    Promise.resolve()
      .then(() => scanImage())
      .then(() => {
        requestRef.current = requestAnimationFrame(animate);
      });
  }, [scanImage]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestRef.current as number);
  }, [animate]);

  return (
    <Webcam
      height={videoHeight ? `${videoHeight}%` : '100%'}
      ref={webcamRef}
      audio={false}
      onUserMediaError={onMediaError}
      screenshotFormat='image/png'
      videoConstraints={videoConstraints}
      id='video'
    />
  );
};
