import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';

export const NotFoundPage: FC = () => {
  return (
    <Box padding='1rem'>
      <Typography variant='h4'>404</Typography>
    </Box>
  );
};
