import { db, ordersDBService, KeyIndex } from '.';
import { Order } from '../../model/order';
import { TicketDb } from '../../model/tse/ticket';

type QueryKey =
  | KeyIndex.FULL_NAME_INDEX
  | KeyIndex.BARCODE_INDEX
  | KeyIndex.ORDER_ID_INDEX
  | KeyIndex.EMAIL_INDEX;

export class AttendeesService {
  async getAttendees(eventIds: string[], key: QueryKey, value: string[], limit?: number | null): Promise<Order[]> {
    const tickets = (await db.transaction('r', db.tickets, async () => {
     const query = db.tickets
        .where(key)
        .startsWithAnyOfIgnoreCase(value)
        .and(friend => eventIds.includes(friend.eventId))
        .distinct();
      if (limit && limit > 0) {
        query.limit(limit)
      }
      return await query.toArray();
    })) as TicketDb[];
    return ordersDBService.transformTicketsToOrders(tickets);
  }

  async findAttendee(eventIds: string[], text: string): Promise<Order[]> {
    const searchWord = text.trim().split(' ');
    let orders: Order[] = [];
    const limit = text.trim() === '' ? 10 : null;
    const tickets = await this.getAttendees(eventIds, KeyIndex.FULL_NAME_INDEX, searchWord, limit);
    if (tickets.length > 0) {
      orders = [...orders, ...tickets];
      return orders;
    }
    orders = await this.getAttendees(eventIds, KeyIndex.EMAIL_INDEX, searchWord, limit);
    if (orders.length > 0) {
      return orders;
    }
    orders = await this.getAttendees(eventIds, KeyIndex.ORDER_ID_INDEX, searchWord, 1);
    if (orders.length > 0) {
      return orders;
    }
    orders = await this.getAttendees(eventIds, KeyIndex.BARCODE_INDEX, searchWord, 1);
    return orders;
  }
}
