const hasError = (inputName, errors) => errors[inputName] !== undefined;

const getErrorMessage = (inputName, errors) => (errors[inputName] !== undefined ? errors[inputName].message : ' ');

const createFormReference = (name, register, errors) => {
  return {
    ...register(name),
    name,
    error: hasError(name, errors),
    helperText: getErrorMessage(name, errors),
  };
};

export { hasError, getErrorMessage, createFormReference };
