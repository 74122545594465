import { createSlice } from '@reduxjs/toolkit';
import { GenericMessage } from '../../model/constants';
import {userLogin} from "../thunks/userThunk";

export interface UserState {
  loading: boolean;
  isAuthenticated: boolean;
  accessToken: string | null;
  userName: string | null;
  errorMessage: string | null;
}

const initialState: UserState = {
  loading: false,
  isAuthenticated: false,
  accessToken: null,
  userName: null,
  errorMessage: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.userName = null;
      state.accessToken = null;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accessToken = payload.accessToken;
      state.userName = payload.userName;
      state.isAuthenticated = true;
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.accessToken = null;
      state.userName = null;
      state.isAuthenticated = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
  },
});

export default userSlice.reducer;

export const { userLogout } = userSlice.actions;
