import {CalendarItem, EventTimeSlots, EventTimeSlotsResponse} from './../../model/event';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event as TsEvent, TimeSlotsItem } from '../../model/event';
import { GenericMessage } from '../../model/constants';
import {findEvents, findMultipleEventsTimeSlots} from "../thunks/eventThunk";
import { getEventSlot, getMinUntilDate } from "../../util/tickeet-utils";

export type EventToScan = {
  event: TsEvent;
  timeSlots: TimeSlotsItem[];
  currentSlot?: CalendarItem;
  minUntilDate?: Date | null;
};

export type EventSlots = {
  event: TsEvent;
  timeSlots: TimeSlotsItem[];
  currentSlot?: CalendarItem;
  minUntilDate?: Date | null;
};

export type EventState = {
  events: TsEvent[];
  isMultipleEvents: boolean;
  selectedEvent: TsEvent | null;
  toScan: EventToScan[];
  eventSlots: EventSlots[];
  eventTimeSlots: EventTimeSlots[];
  selectedEventIdsToScan: string[];
  eventTimeSlotsUpdated: boolean;
  filterQuery: string | null;
  loading: boolean;
  loadingEventDetails: boolean;
  errorMessage: string | null;
};

const initialState: EventState = {
  events: [],
  selectedEvent: null,
  isMultipleEvents: false,
  toScan: [],
  eventSlots: [],
  eventTimeSlots: [],
  selectedEventIdsToScan: [],
  eventTimeSlotsUpdated: false,
  filterQuery: null,
  loading: false,
  loadingEventDetails: false,
  errorMessage: null,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setFilterQuery: (state, { payload }: PayloadAction<string | null>) => {
      state.filterQuery = payload || null;
    },
    setSelectedEvent: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedEvent = state.events.find((ev) => ev.id === payload) || null;
    },
    toggleIsMultipleEvents: (state) => {
      state.isMultipleEvents = !state.isMultipleEvents;
      state.toScan = [];
    },
    cleanEventToScan: (state) => {
      state.selectedEventIdsToScan = [];
    },
    cleanLoadingEventDetails: (state) => {
      state.loadingEventDetails = false;
    },
    addOrRemoveEventToScan: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedEventIdsToScan.includes(payload)) {
        state.selectedEventIdsToScan = state.selectedEventIdsToScan.filter((eventId) => eventId !== payload);
      } else {
        state.selectedEventIdsToScan = [...state.selectedEventIdsToScan, payload];
      }
      state.eventTimeSlotsUpdated = true;
    },
    updateCurrentTimeSlots: (state, { payload }: PayloadAction<boolean>) => {
      const isExistsSlot = (eventId: string) => state.eventSlots.find(es => eventId === es.event.id);
      const updateEventSlot = (eventTimeSlot: EventTimeSlots) => {
        state.eventSlots = state.eventSlots.map(es => {
          if (es.event.id === eventTimeSlot.event.id) {
            const currentSlot = getEventSlot(eventTimeSlot.event, eventTimeSlot);

            return {
              ...es,
              timeSlots: eventTimeSlot?.items,
              currentSlot,
              minUntilDate: getMinUntilDate(eventTimeSlot.event, currentSlot?.recurrenceDateTime),
            }
          }
          return es;
        })
      }
      const addEventSlot = (eventTimeSlot: EventTimeSlots) => {
        const currentSlot = getEventSlot(eventTimeSlot.event, eventTimeSlot);
        state.eventSlots.push({
          event: eventTimeSlot.event,
          timeSlots: eventTimeSlot.items,
          currentSlot,
          minUntilDate: getMinUntilDate(eventTimeSlot.event, currentSlot?.recurrenceDateTime),
        } as EventSlots);
      }
      if(payload){
        state.eventTimeSlots.map((eventTimeSlot) => {
          if (!!isExistsSlot(eventTimeSlot.event.id)) {
            updateEventSlot(eventTimeSlot);
          } else {
            addEventSlot(eventTimeSlot);
          }
        });
        const eventsToScanSlots = state.eventSlots.filter((eventTimeSlot) => {
          return state.selectedEventIdsToScan.includes(eventTimeSlot.event.id)
        });
        state.toScan = eventsToScanSlots.map((eventTimeSlot) => {
          return state.eventSlots.find(es => es.event.id === eventTimeSlot.event.id) as EventToScan;
        });
      }
      state.eventTimeSlotsUpdated = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(findEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findEvents.fulfilled, (state, { payload }) => {
      state.events = payload;
      state.loading = false;
    });
    builder.addCase(findEvents.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(findMultipleEventsTimeSlots.pending, (state) => {
      state.loadingEventDetails = true;
    });
    builder.addCase(
      findMultipleEventsTimeSlots.fulfilled,
      (state, { payload }: PayloadAction<EventTimeSlotsResponse[]>) => {
        const getEvent = (eventId: string) => state.events.find((ev) => ev.id === eventId);
        const isExistsTimeSlots = (eventId: string) => state.eventTimeSlots.find(es => eventId === es.event.id);
        payload.map((eventTimeSlot) => {
          const event = getEvent(eventTimeSlot.eventId);
          if (event) {
            if (!!isExistsTimeSlots(eventTimeSlot.eventId)) {
              state.eventTimeSlots = state.eventTimeSlots.map(ets => {
                if (ets.event.id === eventTimeSlot?.eventId) {
                  return {
                    event,
                    items: eventTimeSlot.items || [],
                  }
                }
                return ets;
              })
            } else {
              state.eventTimeSlots.push({
                event,
                items: eventTimeSlot.items || [],
              });
            }
            state.eventTimeSlotsUpdated = true;
          }
        });
      },
    );
    builder.addCase(findMultipleEventsTimeSlots.rejected, (state, { payload }) => {
      state.loadingEventDetails = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
  },
});

export default eventSlice.reducer;

export const {
  setFilterQuery,
  toggleIsMultipleEvents,
  addOrRemoveEventToScan,
  cleanEventToScan,
  setSelectedEvent,
  updateCurrentTimeSlots,
  cleanLoadingEventDetails
} = eventSlice.actions;
