import {ConfirmReasonCode, GenericMessage, TicketMessages} from './../../model/constants';
import {ApiError, ApiTypeError} from './../../model/api-error';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  CheckinParams,
  CheckinStatus,
  CheckinUncheckinParam,
} from '../../model/checkin';
import {CheckinError} from '../../model/ticket';
import {
  apiCheckinTicket,
  apiCheckinTicketIntApi,
  apiGetTicketsByEventId,
  apiUncheckTicket,
} from '../../service/api-service';
import {
  checkinsDBService,
  PendingTicket,
  PendingTicketState,
  ticketsDBService
} from "../../service/db-service";
import {TicketDb, TicketResponseData} from "../../model/tse/ticket";
import {RootState} from "../rootReducer";
import moment from "moment";
import {transformDataTicket} from "../../util/ticket-transform-data";
import {findOrdersById} from "../thunks/orderThunk";
import {
  removePendingState,
  setEventLastUpdate,
  setSyncProcess,
  updateCheckinTicketsStateByEventId,
  updatePendingState
} from "../slices/ticketSlice";
import { EventToScan, updateCurrentTimeSlots } from "../slices/eventSlice";
import { checkinUncheckOrderTicket } from "../slices/orderSlice";
import { TicketState} from "../../model/tse/order";
import { isBetweenSlot } from "../../util/tickeet-utils";
import { isRecurringEvent } from "../../util/order-util";

const isPromisesResponseOk = (promisesResp) => promisesResp.find((res) => res.success || (!res.success && res.for));

// export const checkinTicket = createAsyncThunk<CheckinStatus, CheckinParams, { rejectValue: ApiError }>(
//   'checkinTicket',
//   async ({ barCode, speedOrder, checkinEvents }, thunkApi) => {
//     try {
//       if (!barCode) {
//         return thunkApi.rejectWithValue({ code: 400, message: GenericMessage.UnknownError });
//       }
//
//       let checkinIntegrationPrimise: Promise<CheckinStatus> = new Promise((resolve) => resolve);
//       let eventId = '';
//       const occurrence = checkinEvents.length === 1 ? checkinEvents[0].occurrence || null : null;
//       thunkApi.dispatch(setScanning({ barCode, occurrence, orderId: null }));
//       const checkinPromises: { eventId: string; reqPromise: Promise<CheckinStatus> }[] = [];
//       checkinEvents.forEach((checkinReq) => {
//         eventId = checkinReq.eventId || '';
//         if (isFiba()) {
//           checkinIntegrationPrimise = apiCheckinTicketIntApi(barCode, eventId, 'pending');
//         }
//         checkinPromises.push({
//           eventId: checkinReq.eventId || '',
//           reqPromise: apiCheckinTicket(barCode, checkinReq.eventId || '', checkinReq.occurrence),
//         });
//       });
//
//       let orderId: string | null = null;
//       let promisesResp;
//
//       const checkinPromisesList = checkinPromises.map((c) => c.reqPromise);
//       if (!speedOrder) {
//         const ticketPromises: { eventId: string; reqPromise: Promise<OrderResponse> }[] = [];
//         checkinEvents.forEach((checkinReq) => {
//           ticketPromises.push({
//             eventId: checkinReq.eventId || '',
//             reqPromise: apiFindOrders({ eventId: checkinReq.eventId || '', ticketBarCode: barCode }),
//           });
//         });
//         const ticketPromiseList = ticketPromises.map((p) => p.reqPromise);
//         const promisesAll = await Promise.all([...ticketPromiseList, ...checkinPromisesList]);
//
//         const ticketsResponses = promisesAll.splice(0, checkinEvents.length) as OrderResponse[];
//         promisesResp = promisesAll.splice(0, checkinEvents.length) as CheckinStatus[];
//         orderId = findFirstOrderId(ticketsResponses);
//       } else {
//         let isTimeout = false;
//         let isCrashed = false;
//         try {
//           // if ts-api is working ok it should be false
//           isTimeout = await checkPromiseTimeout(checkinPromisesList, getServiceTimeout()); // TS
//         } catch (error) {
//           isCrashed = true;
//         }
//
//         if (isTimeout) {
//           // in almost all cases this should be false
//           const intApiTimeout = await checkPromiseTimeout([checkinIntegrationPrimise], 1000); // Integration-API
//
//           if (intApiTimeout) {
//             promisesResp = await Promise.all(checkinPromisesList); // TS
//           } else {
//             promisesResp = [await checkinIntegrationPrimise]; // Integration-API
//             if (!promisesResp[0]?.success && promisesResp[0]?.errorMessage === INTEGRATION_API_FAILS_MSG) {
//               promisesResp = await Promise.all(checkinPromisesList); // TS
//             }
//           }
//         } else {
//           if (!isCrashed) {
//             promisesResp = await Promise.all(checkinPromisesList);
//           } else {
//             // in almost all cases this should be false
//             const intApiTimeout = await checkPromiseTimeout([checkinIntegrationPrimise], getServiceTimeout()); // Integration-API
//
//             if (intApiTimeout) {
//               promisesResp = await Promise.all(checkinPromisesList); // TS
//             } else {
//               promisesResp = [await checkinIntegrationPrimise]; // Integration-API
//               if (!promisesResp[0]?.success && promisesResp[0]?.errorMessage === INTEGRATION_API_FAILS_MSG) {
//                 promisesResp = await Promise.all(checkinPromisesList); // TS
//               }
//             }
//           }
//
//           if (isFiba() && isPromisesResponseOk(promisesResp)) {
//             const intApiTimeout = await checkPromiseTimeout([checkinIntegrationPrimise], 1000); // Integration-API
//             if (!intApiTimeout && !isCrashed) {
//               apiCheckinTicketIntApi(barCode, eventId, 'checked');
//             }
//           }
//         }
//       }
//
//       let checkinRes = isPromisesResponseOk(promisesResp);
//
//       if (!checkinRes) {
//         const knonErrors = promisesResp.find((res) => !res.success || res.errorMessage.starts('TKT-'));
//
//         if (knonErrors) {
//           return thunkApi.rejectWithValue({ code: 400, message: knonErrors.errorMessage });
//         }
//
//         if (!orderId) {
//           return thunkApi.rejectWithValue({ code: 400, message: TicketMessages.TicketByBarcodeError });
//         }
//
//         checkinRes = promisesResp.find((res) => res.errorMessage);
//       }
//
//       if (checkinRes?.success === true && barCode) {
//         checkinRes.barcode = barCode;
//         setSelectedEvent(checkinRes.eventId || null);
//         if (!speedOrder && orderId) {
//           await thunkApi.dispatch(findOrdersById(orderId));
//           checkinRes.orderId = orderId;
//         } else {
//           await thunkApi.dispatch(successScanInSelectedOrders(barCode));
//         }
//
//         return checkinRes;
//       } else if (checkinRes?.for !== undefined && checkinRes?.for !== occurrence) {
//         return checkinRes;
//       } else if (checkinRes) {
//         return thunkApi.rejectWithValue({
//           code: 400,
//           message: checkinRes?.errorMessage || GenericMessage.UnknownError,
//         });
//       } else {
//         return thunkApi.rejectWithValue({ code: 500, message: GenericMessage.UnknownError });
//       }
//     } catch (error) {
//       const e = error as ApiTypeError<CheckinError>;
//       return thunkApi.rejectWithValue({ code: e.code, message: e.data?.errorMessage || e.message } as ApiError);
//     }
//   },
// );

export const checkinTicket = createAsyncThunk<CheckinStatus, CheckinParams, { rejectValue: ApiError }>(
  'checkinTicket',
  async (query, thunkApi) => {
    try {
      const checkinRes = await makeCheckinOffline(query, (params: CheckinUncheckinParam) => {
        thunkApi.dispatch(checkinUncheckOrderTicket(params));
        thunkApi.dispatch(updateCurrentTimeSlots(false));
      });

      if (!checkinRes.valid) {
        const message = getErrorMessage(checkinRes);
        return thunkApi.rejectWithValue({ code: 400, message });
      }
      if (checkinRes.orderId){
        thunkApi.dispatch(updateOrderAfterCheckin(checkinRes.orderId));
      }

      return checkinRes;
    } catch (error) {
      console.log('error ', error);
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

const makeCheckinOffline = async (
  query: CheckinParams,
  refreshOrderCallback: (params: CheckinUncheckinParam) => void,
): Promise<CheckinStatus> => {
  const { barCode, speedOrder, checkinEvents, allowUnCheckin, forceConfirmation } = query;

  const ticket = await ticketsDBService.getTicketByBarcode(barCode);
  if (!ticket) {
    return { valid: false, reason: TicketMessages.TicketNotFound };
  }

  const { id, orderId, ticketStatus } = ticket;
  let checkinEvent = checkinEvents.find(ce => ce.eventId === ticket.eventId || ce.parentEvents?.find(pe => pe === ticket.eventId))
  if (!checkinEvent) {
    return { valid: false, reason: TicketMessages.AnotherEventError };    
  }
  const { eventId } = checkinEvent;
  if (!eventId) {
    return { valid: false, reason: TicketMessages.AnotherEventError };
  }

  if (!forceConfirmation
    && isRecurringEvent(checkinEvent)
    && ticketStatus === TicketState.Active
    && !isBetweenSlot(checkinEvent))
  {
    return {
      valid: true,
      barcode: barCode,
      success: false,
      reason: TicketMessages.AnotherTimeSlotError,
      confirmReasonCode: ConfirmReasonCode.AnotherTimeSlot,
      for: checkinEvent.occurrence || '',
    };
  }

  if(!allowUnCheckin && ticket.ticketStatus === TicketState.Redeemed){
    return { valid: false, reason: TicketMessages.AlreadyScannedError };
  }

  const pending = await checkinsDBService.getPendingTicket(barCode);
  if (pending && (pending.state === PendingTicketState.PENDING || pending.state === PendingTicketState.ERROR)) {
    await checkinsDBService.deletePendingTicket(id);
    if (ticketStatus === TicketState.Active) {
      ticket.ticketStatus = TicketState.Redeemed;
      ticket.redemptionInfo.redeemed = true;
      ticket.redemptionInfo.dateRedeemedUtc = new Date().toString();
    }
    if (ticketStatus === TicketState.Redeemed) {
      ticket.ticketStatus = TicketState.Active;
      ticket.redemptionInfo.redeemed = false;
    }
    await ticketsDBService.updateTicket(ticket);
    refreshOrderCallback({
      eventId,
      ticketId: id,
      barCode,
      occurrence: ticket.ticketTypeInfo.event?.recurrenceDateTime,
      redeemed: ticket.redemptionInfo.redeemed
    });
    return {
      valid: true,
      orderId,
      barcode: barCode,
    };
  }

  if (ticketStatus === TicketState.Active || ticketStatus === TicketState.Redeemed) {
    if (ticketStatus === TicketState.Active) {
      ticket.ticketStatus = TicketState.Redeemed;
      ticket.redemptionInfo.redeemed = true;
      ticket.redemptionInfo.dateRedeemedUtc = new Date().toString();
    }
    if (ticketStatus === TicketState.Redeemed) {
      ticket.ticketStatus = TicketState.Active;
      ticket.redemptionInfo.redeemed = false;
    }
    await ticketsDBService.updateTicket(ticket);

    if (pending && (pending.state === PendingTicketState.SYNCED)) {
      await checkinsDBService.updatePendingTicket({
        ticket: ticket,
        id,
        barCode,
        speedOrder,
        checkinEvents,
        eventId: eventId,
        state: PendingTicketState.PENDING,
      } as PendingTicket);
    } else {
      await checkinsDBService.addPendingCheckin({
        ticket: ticket,
        id,
        barCode,
        speedOrder,
        checkinEvents,
        eventId: eventId,
        state: PendingTicketState.PENDING,
      } as PendingTicket);
    }

    refreshOrderCallback({
      eventId,
      ticketId: id,
      barCode,
      occurrence: ticket.ticketTypeInfo.event?.recurrenceDateTime,
      redeemed: ticket.redemptionInfo.redeemed
    });

    return {
      valid: true,
      orderId,
      barcode: barCode,
    };
  }

  return { valid: false, reason: TicketMessages.UnknownError };
};
//
// export const uncheckinTicket = createAsyncThunk<boolean, UncheckinParam, { rejectValue: ApiError }>(
//   'uncheckinTicket',
//   async (uncheckinParmas, thunkApi) => {
//     try {
//       const { eventId, ticketId, barCode, occurrence } = uncheckinParmas;
//       if (!ticketId) {
//         return thunkApi.rejectWithValue({ code: 400, message: GenericMessage.UnknownError });
//       }
//
//       const success = await apiUncheckTicket(eventId, barCode, occurrence);
//       if (success) {
//         apiCheckinTicketIntApi(barCode, eventId, 'uncheck');
//         await thunkApi.dispatch(uncheckOrderTicket(uncheckinParmas));
//         return true;
//       }
//
//       thunkApi.rejectWithValue({ code: 500, message: GenericMessage.UnknownError } as ApiError);
//     } catch (error) {
//       const e = error as ApiTypeError<CheckinError>;
//       thunkApi.rejectWithValue({ code: e.code, message: e.data?.errorMessage || e.message } as ApiError);
//     } finally {
//       return false;
//     }
//   },
// );

export const updateOrderAfterCheckin = createAsyncThunk<
  string | null,
  string,
  { rejectValue: ApiError }
>('updateOrderAfterCheckin', async (orderId, thunkApi) => {
  try {
    await thunkApi.dispatch(findOrdersById(orderId));
    return orderId;
  } catch (error) {
    const e = error as ApiTypeError<CheckinError>;
    thunkApi.rejectWithValue({ code: e.code, message: e.data?.errorMessage || e.message } as ApiError);
    return null;
  }
});


type RetrieveTicketsType = {
  eventId: string;
  tickets: TicketDb[];
};
export const retrieveTicketsByEventId = createAsyncThunk<
  RetrieveTicketsType,
  string,
  { rejectValue: ApiError & { eventId: string } }
  >('retrieveTicketsByEventId', async (eventId, thunkApi) => {
  try {
    const tickets = await ticketsDBService.getTicketByEvent(eventId);

    return { eventId, tickets };
  } catch (error) {
    return thunkApi.rejectWithValue({ ...(error as ApiError), eventId });
  }
});

export const syncTicketsFromServer = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'ticketsSlice/syncTicketsFromServer',
  async (_: void, thunkApi) => {
    const store = thunkApi.getState() as RootState;
    const toScan = store.event.toScan as EventToScan[];
    const eventsLastUpdate = store.ticket.eventsLastUpdate;
    for (const event of toScan) {
      const eventId = event.event.id;
      const lastUpdate = eventsLastUpdate[eventId] || 0;
      const result = await apiGetTicketsByEventId(eventId, lastUpdate + 1, updateTicketsInIndexDb);
      if (result.length > 0) {
        const lastUpdate = Math.max(0, ...result.map((ticket) => parseInt(moment(ticket.dateModifiedUtc).add(1, 's').format('x'))));
        thunkApi.dispatch(setEventLastUpdate({ eventId, lastUpdate }));
        thunkApi.dispatch(retrieveTicketsByEventId(eventId));
      }
    }
  },
);

const updateTicketsInIndexDb = async (orders: TicketResponseData[]): Promise<void> => {
  orders.map(order => {
    const { lineItems } = order;
    lineItems.map(({ tickets }) => {
      tickets.map(async ticket => await ticketsDBService.updateTickets([transformDataTicket(order, ticket)]));
    });
  });
}

export const syncPendingTicket = createAsyncThunk<void, PendingTicket, { rejectValue: ApiError }>(
  'syncPendingTicket',
  async (pendingTicket, thunkApi) => {
    const pending: PendingTicket = { ...pendingTicket } as PendingTicket;
    const occurrence = pending.checkinEvents.length && pending.checkinEvents[0].occurrence;
    const overrideTicketOccurrence = pending.checkinEvents.length && pending.checkinEvents[0].overrideTicketOccurrence;
    try {
      let success = false;
      let message = '';
      if(pending.ticket.ticketStatus === TicketState.Redeemed){
        const checkinResults = await apiCheckinTicket(
          pending.ticket.ticketBarCode,
          pending.eventId,
          occurrence || null,
          !!overrideTicketOccurrence
        );
        const checkinRes = checkinResults[0];
        success = checkinRes.success || false;
        message = getErrorMessage(checkinRes);
      } else if (pending.ticket.ticketStatus === TicketState.Active){
        success = await apiUncheckTicket(
          pending.ticket.eventId,
          pending.ticket.ticketBarCode,
          occurrence || '',
          !!overrideTicketOccurrence
        );
        message = GenericMessage.UnknownError;
      }

      if (success) {
        pending.state = PendingTicketState.SYNCED;
        pending.message = 'Checked in successfully';
      } else {
        pending.state = PendingTicketState.ERROR;
        pending.message = message;
      }
    } catch (error) {
      const apiError = error as ApiError;
      pending.state = PendingTicketState.ERROR;
      pending.message = apiError.message || GenericMessage.UnknownError;
    }
    await checkinsDBService.updatePendingTicket(pending);
    thunkApi.dispatch(updatePendingState(pending));
    if (pending.state === PendingTicketState.SYNCED) {
      thunkApi.dispatch(updateCheckinTicketsStateByEventId({ eventId: pending.ticket.eventId }));
    }
  },
);

const getErrorMessage = (checkinRes: CheckinStatus): string => {
  const reason = checkinRes.reason as string;
  if(reason){
    return reason;
  }
  const errorMessage = checkinRes.errorMessage as string;
  const knonErrors = errorMessage?.substring(0, 3) === 'TKT-';
  if (knonErrors) {
   return errorMessage
  }
  if (!checkinRes.orderId) {
    return TicketMessages.TicketByBarcodeError
  }
  return TicketMessages.UnknownError;
};

export interface PendingTicketReq {
  offset: number;
  limit: number;
}

export const retrievePendingTickets = createAsyncThunk<PendingTicket[], PendingTicketReq, { rejectValue: ApiError }>(
  'retrievePendingTickets',
  async ({ offset, limit }, thunkApi) => {
    try {
      return await checkinsDBService.getPendingTickets(offset, limit);
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const removePendingTickets = createAsyncThunk<void, PendingTicket, { rejectValue: ApiError }>(
  'removePendingTickets',
  async (pendingTicket, thunkApi) => {
    try {
      await checkinsDBService.deletePendingTicket(pendingTicket.id);
      thunkApi.dispatch(removePendingState(pendingTicket));
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const syncPendingTickets = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'syncPendingTickets',
  async (_: void, thunkApi) => {
    try {
      const pendings = [
        ...(await checkinsDBService.getPendingTicketsByState(PendingTicketState.PENDING)),
        ...(await checkinsDBService.getPendingTicketsByState(PendingTicketState.ERROR)),
      ];

      for (const pending of pendings) {
        await thunkApi.dispatch(syncPendingTicket(pending));
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const clearSyncPendingTickets = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'clearSyncPendingTickets',
  async (_: void, thunkApi) => {
    try {
      const pendings = await checkinsDBService.getPendingTicketsByState(PendingTicketState.SYNCED);
      for (const pending of pendings) {
        await checkinsDBService.deletePendingTicket(pending.id);
        thunkApi.dispatch(removePendingState(pending));
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const syncProcess = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'ticketsSlice/syncProcess',
  async (_: void, thunkApi) => {
    const store = thunkApi.getState() as RootState;
    const { syncProcess, syncProcessStart } = store.ticket;
    const { loading } = store.settings;

    if (loading) {
      return;
    }

    if (!syncProcess) {
      thunkApi.dispatch(setSyncProcess(true));
      await thunkApi.dispatch(syncPendingTickets());
      await thunkApi.dispatch(syncTicketsFromServer());
    } else {
      console.warn('Sync tickets already running');
    }
  },
);