import { TranlationKeys } from './translate';

export const translationEn: TranlationKeys = {
  login: 'Log in',
  logout: 'Log out',
  universal: 'Universal',
  checkIn: 'Check-in',
  username: 'Username',
  Password: 'Password',
  siteID: 'Site ID',
  authenticationFailed: 'Authentication Failed',
  findEvents: 'Find Events...',
  today: 'Today',
  upcoming: 'Upcoming',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  viewSearchAttendees: 'View / Search Attendees',
  beginCameraScanning: 'Begin Camera Scanning',
  beginLaserScanning: 'Begin Laser Scanning',
  findAttendees: 'Find Attendees...',
  searchtermsMayInclude: 'Search terms may include:',
  name: 'Name',
  orderNumber: 'Order Number',
  email: 'Email',
  attendeeList: 'Attendee List',
  noAttendeesFound: 'No attendees found',
  readyToScan: 'Ready to scan.',
  barcode: 'Barcode',
  enter: 'Enter',
  checkedIn: 'Checked In',
  details: 'Details',
  ticketCanNotBeScanned:
    'TKT-00007 - This ticket cannot be sacanned because it does not match the Event it is being scanned for',
  ticketCanNotBeProcessed: 'TKT-00014 - This ticket cannot be processed as it has been canceled',
  ticketAlreadyRedeemed: 'TKT-00005 - This ticket has already been redeemed for this Event',
  EventOrBarCodeDoesntexist: 'Event or barcode does not exist',
  unknowEerror: 'Unknown error',
  purchased: 'Purchased',
  purchaser: 'Purchaser',
  hideOrderDetails: 'Hide Order Details',
  showOrderDetails: 'Show Order Details',
  closeScanner: 'Close Scanner',
  continueScanning: 'Continue Scanning',
};
