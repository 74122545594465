/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { format, addMinutes } from 'date-fns';
import {Order, OrderTicket, TicketTotalStatus} from '../model/order';
import { EventToScan } from '../store/slices/eventSlice';
import { CheckinEvent, CheckinParams } from "../model/checkin";
import moment from "moment";
import { cleanDeviceOffset } from "./event-date-utils";
import {CalendarItem, Event as TsEvent, EventTimeSlot, EventTimeSlots, EventTimeSlotsResponse} from "../model/event";
import {TicketState} from "../model/tse/order";
import {TicketDb} from "../model/tse/ticket";

const getTimeSlotFromTicket = (ticket: OrderTicket): string => {
  return ticket.redemptionInfo?.dateRedeemedUtc
    ? `Reedemed: ${format(new Date(ticket.redemptionInfo?.dateRedeemedUtc), 'MMM d, h:mm a')}`
    : ''
};

const getTimeSlotLabel = (ticket: OrderTicket, event: TsEvent | null): string => {
  return event?.isRecurringEvent
    ? `Time Slot: ${format(cleanDeviceOffset(ticket.ticketTypeInfo.event.recurrenceDateTime), 'MMM d, h:mm a')}`
    : ''
};

const getTimeSlotsFromOrder = (order: Order): string =>
  order.lineItems[0]?.tickets
    .map((t) => getTimeSlotFromTicket(t))
    .filter((value, index, array) => array.indexOf(value) === index)
    .join(' - ');

const getUntilSlotDate = (event: TsEvent, recurrenceDateTime: string | undefined): Date | null => {
  const timeSlot = recurrenceDateTime ? cleanDeviceOffset(recurrenceDateTime) : new Date();
  const eventSlot = event.recurrenceInfo?.eventTimeSlots.find((e) => e.timeSlot === format(timeSlot, 'HH:mm'));
  if (!!eventSlot) {
    return moment(addMinutes(timeSlot, eventSlot.intervalInMinutes)).toDate();
  }
  return null;
}

const getSlot = (event: TsEvent, recurrenceDateTime: string | undefined): EventTimeSlot | undefined => {
  const timeSlot = recurrenceDateTime ? cleanDeviceOffset(recurrenceDateTime) : new Date();
  return event.recurrenceInfo?.eventTimeSlots.find((e) => e.timeSlot === format(timeSlot, 'HH:mm'));
}

const isBetweenSlot = (checkinEvent: CheckinEvent): boolean => {
  const { occurrence, currentSlot, untilDateSlot } = checkinEvent;

  const occurrenceDateEvent = cleanDeviceOffset(occurrence || '');
  const startDateSlot = cleanDeviceOffset(currentSlot?.recurrenceDateTime || '');

  return moment(occurrenceDateEvent).isBetween(startDateSlot, untilDateSlot, undefined, '[]')
}

const getMinUntilDate = (event: TsEvent, recurrenceDateTime: string | undefined): Date | null => {
  const { redeemAtEndOfTimeslot, redeemMinutesAfter } = event;
  const untilSlotDate = getUntilSlotDate(event, recurrenceDateTime);
  const startDateSlot = recurrenceDateTime ? cleanDeviceOffset(recurrenceDateTime) : new Date();
  const endRuleSlot = moment(addMinutes(startDateSlot, redeemMinutesAfter)).toDate();

  if (redeemAtEndOfTimeslot) {
    return untilSlotDate;
  }

  if(redeemMinutesAfter === 0) {
    return startDateSlot;
  }

  if(moment(untilSlotDate).isBefore(endRuleSlot)){
    return untilSlotDate;
  }

  return endRuleSlot;
}

const isAllowedToCheckin = (checkinEvent: CheckinEvent): boolean => {
  const { isRecurringEvent, occurrence, currentSlot, minUntilDate, untilDateSlot, redeemAtEndOfTimeslot, redeemMinutesAfter } = checkinEvent;
  if (!isRecurringEvent) {
    return true;
  }

  const occurrenceDateEvent = new Date(occurrence || '');
  const startDateSlot = new Date(currentSlot?.recurrenceDateTime || '');

  if (redeemAtEndOfTimeslot) {
    // Is between slot
    return moment(occurrenceDateEvent).isBetween(startDateSlot, untilDateSlot, undefined, '[]')
  }
  if(redeemMinutesAfter === 0) {
    // Is before to start
    return moment(occurrenceDateEvent).isBefore(startDateSlot);
  }

  // Is between slot considering the time limit in the admin
  return moment(occurrenceDateEvent).isBetween(startDateSlot, minUntilDate, undefined, '[]')
}

const getCheckinEvent = (
  eventToScan: EventToScan,
  occurrence: string | null,
  eventTimeSlots: EventTimeSlots[]
): CheckinEvent => {
  const eventTimeSlot = eventTimeSlots?.find(ets => ets.event.id === eventToScan.event.id);
  let currentSlot = eventToScan.currentSlot;
  let minUntilDate = eventToScan.minUntilDate;

  if (eventTimeSlot) {
    currentSlot = getEventSlot(eventTimeSlot.event, eventTimeSlot);
    minUntilDate = getMinUntilDate(eventTimeSlot.event, currentSlot?.recurrenceDateTime);
  }
  return {
    eventId: eventToScan.event.id,
    occurrence: occurrence ? occurrence : null,
    overrideTicketOccurrence: !occurrence,
    isRecurringEvent: eventToScan.event.isRecurringEvent,
    redeemAtEndOfTimeslot: eventToScan.event.redeemAtEndOfTimeslot,
    redeemMinutesAfter: eventToScan.event.redeemMinutesAfter,
    recurrenceInfo: eventToScan.event.recurrenceInfo || null,
    currentSlot,
    untilDateSlot: getUntilSlotDate(eventToScan.event, eventToScan.currentSlot?.recurrenceDateTime),
    minUntilDate,
    parentEvents: eventToScan.event.parentEvents.map(p => p.id)
  };
};

const getCheckinParams = (
  eventsToScan: EventToScan[],
  barCode: string,
  occurrence: string | null,
  eventTimeSlots: EventTimeSlots[]
): CheckinParams => {
  return {
    barCode,
    speedOrder: true,
    forceConfirmation: false,
    checkinEvents: eventsToScan.map((eventToScan) => getCheckinEvent(eventToScan, occurrence, eventTimeSlots))
  };
};

const isInCurrentTimeSlot = (event: TsEvent, recurrenceDateTime: string | undefined): boolean => {
  const { isRecurringEvent, redeemAtEndOfTimeslot, redeemMinutesAfter } = event;
  if (!isRecurringEvent) {
    return true;
  }
  const currentDate = new Date();
  const startDateSlot = cleanDeviceOffset(recurrenceDateTime);
  const untilDateSlot = getUntilSlotDate(event, recurrenceDateTime);
  const slot = getSlot(event, recurrenceDateTime);

  if (redeemAtEndOfTimeslot) {
    // Is before to end
    return moment(currentDate).isBefore(untilDateSlot)
  }

  if(redeemMinutesAfter === 0) {
    // Is before to start
    return moment(currentDate).isBefore(startDateSlot);
  }

  // Is before to the end rule, considering the time limit in the admin
  const minUntilDate = getMinUntilDate(event, recurrenceDateTime);
  return moment(currentDate).isBefore(minUntilDate);
}

const getEventNextSlot = (currentSlot: CalendarItem, eventTimeSlot: EventTimeSlotsResponse) => {
  let slot;
  if (eventTimeSlot.items && eventTimeSlot?.items.length) {
    let currentSlotIndex = eventTimeSlot?.items[0].calendarItems.findIndex((calendarItem) => currentSlot.recurrenceDateTime === calendarItem.recurrenceDateTime) || -1;
    if (currentSlotIndex > -1){
      slot = eventTimeSlot?.items[0].calendarItems[currentSlotIndex + 1] || null;
    }
    if (!slot && eventTimeSlot?.items.length > 1) {
      currentSlotIndex = eventTimeSlot?.items[1].calendarItems.findIndex((calendarItem) => currentSlot.recurrenceDateTime === calendarItem.recurrenceDateTime) || -1;
      if (currentSlotIndex > -1){
        slot = eventTimeSlot?.items[1].calendarItems[currentSlotIndex + 1] || null;
      }
    }
  }
  return slot;
}

const getEventSlot = (event: TsEvent, eventTimeSlot: EventTimeSlots) => {
  let slot;
  if (eventTimeSlot.items && eventTimeSlot?.items.length) {
    slot = eventTimeSlot?.items[0].calendarItems.find((calendarItem) => isInCurrentTimeSlot(event, calendarItem.recurrenceDateTime)) || null;
    if(!slot && eventTimeSlot?.items.length > 1){
      slot = eventTimeSlot?.items[1].calendarItems.find((calendarItem) => isInCurrentTimeSlot(event, calendarItem.recurrenceDateTime)) || null;
    }
  }
  return slot;
}

const getTicketTotalsByRecurrentEvent = (
  event: TsEvent,
  tickets: TicketDb[],
  slot?: CalendarItem | null
): TicketTotalStatus => {
  const totalCount = slot?.stats?.total ? slot?.stats?.total : 0;

  if (totalCount === 0) {
    return getTicketTotalsWithoutCapacity(event, tickets, slot);
  }

  const bookedCount = slot?.stats?.booked ? slot?.stats?.booked : 0;
  const bookedPer = (bookedCount * 100) / (totalCount !== -1 ? totalCount : 100);

  const checkedInCount = getTotalTicketsByEventSlot(event, tickets, TicketState.Redeemed, slot);
  const checkedInPer = (checkedInCount * 100) / (totalCount !== -1 ? totalCount : 100);

  return {
    checkedInCount,
    bookedCount,
    totalCount,
    checkedInPer,
    bookedPer,
  }
}

const getTicketTotalsWithoutCapacity = (
  event: TsEvent,
  tickets: TicketDb[],
  slot?: CalendarItem | null
): TicketTotalStatus => {
  const bookedCount = slot?.stats?.booked ? slot?.stats?.booked : 0;
  const bookedPer = 100;

  const checkedInCount = getTotalTicketsByEventSlot(event, tickets, TicketState.Redeemed, slot);
  const checkedInPer = (checkedInCount * 100) / (bookedCount);

  return {
    checkedInCount,
    bookedCount,
    totalCount: 0,
    checkedInPer,
    bookedPer,
  }
}

const getTicketTotalsSingleEvent = (
  event: TsEvent,
  tickets: TicketDb[],
): TicketTotalStatus => {
  const bookedCount = getTotalTicketsByEventSlot(event, tickets);

  const checkedInCount = getTotalTicketsByEventSlot(event, tickets, TicketState.Redeemed);
  const checkedInPer = (checkedInCount * 100) / (bookedCount !== -1 ? bookedCount : 100);

  return {
    checkedInCount,
    bookedCount,
    totalCount: 0,
    checkedInPer,
    bookedPer: 100,
  }
}

const getTotalTicketsByEventSlot = (event: TsEvent, tickets: TicketDb[], ticketState?: TicketState, slot?: CalendarItem | null): number => {
  return tickets
    .filter(t =>
      (!ticketState
        || t.ticketStatus === ticketState)
      && t.ticketTypeInfo.event.recurrenceDateTime === slot?.recurrenceDateTime
      && isInCurrentTimeSlot(event, t.ticketTypeInfo.event.recurrenceDateTime))
    .length;
}

const compareTicketsByTimeSlotAsc = (ticketA: OrderTicket, ticketB: OrderTicket) =>
  new Date(ticketA.ticketTypeInfo.event.recurrenceDateTime).getTime() - new Date(ticketB.ticketTypeInfo.event.recurrenceDateTime).getTime()

export {
  getCheckinParams,
  getUntilSlotDate,
  getTimeSlotsFromOrder,
  getTimeSlotFromTicket,
  isAllowedToCheckin,
  isBetweenSlot,
  getEventSlot,
  getEventNextSlot,
  getMinUntilDate,
  getTimeSlotLabel,
  getTicketTotalsByRecurrentEvent,
  getTicketTotalsWithoutCapacity,
  getTicketTotalsSingleEvent,
  compareTicketsByTimeSlotAsc,
};