import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericMessage } from '../../model/constants';
import { CheckinUncheckinParam } from '../../model/checkin';
import { OrderLineItem, Order } from '../../model/order';
import { findBooksBySlot, findOrders, findOrdersById } from "../thunks/orderThunk";
import {TicketState} from "../../model/tse/order";

export type OrderState = {
  orders: Order[];
  selectedOrder: Order | null;
  loading: boolean;
  errorMessage: string | null;
};

const initialState: OrderState = {
  orders: [],
  selectedOrder: null,
  loading: false,
  errorMessage: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    cleanOrders: (state) => {
      state.orders = [];
    },
    setSelectedOrder: (state, { payload }: PayloadAction<Order>) => {
      state.selectedOrder = payload;
    },
    successScanInSelectedOrders: (state, { payload }: PayloadAction<string>) => {
      let lineItems = (state.selectedOrder?.lineItems || []) as OrderLineItem[];

      if (lineItems.length > 0) {
        lineItems = lineItems.map((lineItem) => {
          let tickets = lineItem?.tickets;

          tickets =
            tickets?.map((ticket) => {
              if (ticket.ticketBarCode === payload) {
                ticket.redemptionInfo.redeemed = true;
              }
              return ticket;
            }) || [];

          return { ...lineItem, tickets };
        });

        state.selectedOrder = { ...state.selectedOrder, lineItems } as Order;
      }
    },
    checkinUncheckOrderTicket: (state, { payload }: PayloadAction<CheckinUncheckinParam>) => {
      let lineItems = (state.selectedOrder?.lineItems || []) as OrderLineItem[];
      if (lineItems.length > 0) {
        lineItems = lineItems.map((lineItem) => {
          let tickets = lineItem?.tickets;

          tickets =
            tickets?.map((ticket) => {
              if (ticket.ticketBarCode === payload.barCode) {
                ticket.redemptionInfo.redeemed = payload.redeemed;
                ticket.ticketStatus = payload.redeemed ? TicketState.Redeemed : TicketState.Active;
              }
              return ticket;
            }) || [];

          return { ...lineItem, tickets };
        });

        state.selectedOrder = { ...state.selectedOrder, lineItems } as Order;
        state.orders = state.orders.map((order) => {
          if (order.id === state.selectedOrder?.id) {
            return {
              ...order,
              lineItems
            }
          }
          return order;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findOrders.pending, (state) => {
      state.orders = [];
      state.loading = true;
      state.errorMessage = null;
    });
    builder.addCase(findOrders.fulfilled, (state, { payload }) => {
      state.orders = payload;
      state.loading = false;
    });
    builder.addCase(findOrders.rejected, (state, { payload }) => {
      state.orders = [];
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(findOrdersById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findOrdersById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.selectedOrder = payload;
    });
    builder.addCase(findOrdersById.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(findBooksBySlot.pending, (state) => {
      state.loading = true;
      state.orders = [];
    });
    builder.addCase(findBooksBySlot.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
    });
    builder.addCase(findBooksBySlot.rejected, (state, { payload }) => {
      state.loading = false;
      state.orders = [];
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
  },
});

export default orderSlice.reducer;

export const {
  cleanOrders,
  successScanInSelectedOrders,
  checkinUncheckOrderTicket,
  setSelectedOrder
} = orderSlice.actions;
