import React, { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { EventToScan } from '../../store/slices/eventSlice';
import { CalendarItem, Event as TsEvent } from '../../model/event';
import { cleanDeviceOffset } from '../../util/event-date-utils';
import { addMinutes, format } from 'date-fns';

const useStyles = makeStyles(() => ({
  eventList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
    borderBottom: '1px solid rgba(0, 0, 0, .2)',
  },
}));

interface EventTimeSlotsProps {
  eventScan: EventToScan;
}

const EventTimeSlots: FC<EventTimeSlotsProps> = ({ eventScan }) => {
  const classes = useStyles();

  const currentSlot: CalendarItem | null = eventScan?.currentSlot || null;
  const dateSlot = currentSlot?.recurrenceDateTime ? cleanDeviceOffset(currentSlot?.recurrenceDateTime) : new Date();

  const eventSlot = (eventScan.event as TsEvent)?.recurrenceInfo?.eventTimeSlots.find(
    (e) => e.timeSlot === format(dateSlot, 'HH:mm'),
  );

  const untilDateSlot: Date | null = eventSlot ? addMinutes(dateSlot, eventSlot.intervalInMinutes) : null;

  return (
    <Box className={classes.eventList} key={eventScan.event.id}>
      <Typography variant='body1'>{eventScan.event.title}</Typography>
      {eventScan.event.isRecurringEvent && (
        <Typography variant='body1'>
          {format(dateSlot, 'h:mm a')} {untilDateSlot ? `- ${format(untilDateSlot, 'h:mm a')}` : ''}
        </Typography>
      )}
    </Box>
  );
};

export default EventTimeSlots;
