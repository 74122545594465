import { combineReducers } from '@reduxjs/toolkit';
import settingsReducer from './slices/settingsSlice';
import userReducer from './slices/userSlice';
import eventReducer from './slices/eventSlice';
import orderReducer from './slices/orderSlice';
import ticketReducer from './slices/ticketSlice';
import modalConfirmationSlice from './slices/modalSlice';

const appReducer = combineReducers({
  settings: settingsReducer,
  user: userReducer,
  event: eventReducer,
  order: orderReducer,
  ticket: ticketReducer,
  modalConfirmation: modalConfirmationSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'settings/reduxReset') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>;

export type RootReducerType = typeof rootReducer;

export default rootReducer;

