import React, {FC, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import { isAfter } from 'date-fns';
import { EventRow } from '../display/event-row';
import { useHistory } from 'react-router-dom';
import { SearchInput } from '../display/search-input';
import { fixDateIfApplyAndCleanOffset, getTodayAtEndOfDay, lessOrEqualsThanToday } from '../../util/event-date-utils';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import {
  toggleIsMultipleEvents,
  addOrRemoveEventToScan,
  cleanEventToScan,
  setSelectedEvent
} from '../../store/slices/eventSlice';
import {
  selectEvents,
  selectEventsLastUpdate,
  selectEventsToScanIds,
  selectIsMultipleEvents,
  selectLoadingEvents
} from '../../store/selectors';
import {findEvents} from "../../store/thunks/eventThunk";
import {Event} from "../../model/event";

const MULTIPLE_EVENTS_FLAG = process.env.SCANNER_MULTIPLE_EVENTS_FLAG === 'true'? true: false;

const useStyles = makeStyles((theme: Theme) => ({
  upcoming: {
    opacity: 0.5,
  },
  subtitle: {
    fontSize: '0.9375rem',
  },
  eventsContainer: {
    height: '100%',
    margin: '0 1.125rem',
    paddingTop: '1.5rem',
    paddingBottom: '3.5rem',
    overflow: 'scroll',
    paddingRight: '1.125rem',
    marginRight: '0px',
  },
  bottomBar: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    position: 'fixed',
    bottom: '0',
    display: 'flex-wrap',
    flexDirection: 'column',
    padding: '1.25rem',
  },
  selectMultEventsBtn: {
    padding: '1px 8px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  loadingIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vh - 100px)',
  },
  justifyCenter: {
    justifyContent:"center"
  },
}));

export const LandingPage: FC = () => {
  const classes = useStyles();
  const events = useAppSelector(selectEvents);
  const [eventsFiltered, setEventsFiltered] = useState<Event[]>([]);
  const [eventsToday, setEventsToday] = useState<Event[]>([]);
  const [eventsUpcoming, setEventsUpcoming] = useState<Event[]>([]);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const eventsToScanIds = useAppSelector(selectEventsToScanIds);
  const eventsLastUpdate = useAppSelector(selectEventsLastUpdate);
  const loadingEvents = useAppSelector(selectLoadingEvents);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(cleanEventToScan())
    dispatch(setSelectedEvent(null))
    dispatch(findEvents({ searchTerm: '' }));
  }, []);

  useEffect(() => {
    setEventsFiltered(events);
  }, [events]);

  const handleFindEvents = (text: string) => {
    const regExp = new RegExp(text, 'gi');
    setEventsFiltered(events.filter(e => e.title.match(regExp)));
  };

  useEffect(() => {
    setEventsToday(eventsFiltered.filter((event) => lessOrEqualsThanToday(new Date(event.eventStartDate))));
    setEventsUpcoming(eventsFiltered.filter((event) => isAfter(new Date(event.eventStartDate), getTodayAtEndOfDay())));
  }, [eventsFiltered]);

  const isEventRetrieved = (eventId: string) => {
    return eventsLastUpdate[eventId] !== undefined;
  };

  const onSelectEvent = (eventId: string) => {
    if (isMultipleEvents && MULTIPLE_EVENTS_FLAG) {
      dispatch(addOrRemoveEventToScan(eventId));
    } else {
      dispatch(setSelectedEvent(null))
      dispatch(addOrRemoveEventToScan(eventId));
      history.push(`/event/${eventId}`);
    }
  };

  if (loadingEvents) {
    return (
      <Box className={classes.loadingIndicator}>
        <Grid>
          <Grid item xs={12} container alignItems="center" className={classes.justifyCenter}>
            <CircularProgress />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' color='textSecondary'>Retrieving events</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box>
        <SearchInput placeholder={'Find Events...'} handleSubmit={handleFindEvents} />
      </Box>

      <Box className={classes.eventsContainer}>
        {eventsToday.length === 0 && (
          <>
            <Typography variant='subtitle2' className={classes.subtitle}>
              No events were found
            </Typography>
          </>
        )}

        {eventsToday.length > 0 && (
          <>
            <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='1.25rem'>
              <Typography variant='subtitle2' className={classes.subtitle}>
                Today
              </Typography>
              {MULTIPLE_EVENTS_FLAG && (
                <Button
                  className={classes.selectMultEventsBtn}
                  onClick={() => {
                    dispatch(toggleIsMultipleEvents());
                  }}
                  variant={isMultipleEvents ? 'outlined' : 'contained'}
                  color='primary'
                >
                  Select Multiple Events {isMultipleEvents ? 'X' : ''}
                </Button>
              )}
            </Box>
            {eventsToday.map((event) => (
              <Box onClick={() => onSelectEvent(event.id)} key={event.id}>
                <EventRow
                  weekday={true}
                  event={event}
                  isRetrieved={isEventRetrieved(event.id)}
                  displayDate={getTodayAtEndOfDay()}
                  isMultipleEvents={MULTIPLE_EVENTS_FLAG && isMultipleEvents}
                  isSelected={eventsToScanIds.includes(event.id)}
                />
              </Box>
            ))}
          </>
        )}
        {eventsUpcoming.length > 0 && (
          <>
            <Typography variant='subtitle2' className={classes.subtitle}>
              Upcoming
            </Typography>
            <div className={classes.upcoming}>
              {eventsUpcoming.map((event) => (
                <Box onClick={() => onSelectEvent(event.id)} key={event.id}>
                  <EventRow
                    weekday={true}
                    isRetrieved={isEventRetrieved(event.id)}
                    event={event}
                    displayDate={fixDateIfApplyAndCleanOffset(event.eventStartDate)}
                    isMultipleEvents={isMultipleEvents}
                    isSelected={eventsToScanIds.includes(event.id)}
                  />
                </Box>
              ))}
            </div>
          </>
        )}
      </Box>
      {MULTIPLE_EVENTS_FLAG && isMultipleEvents && eventsToScanIds?.length > 0 && (
        <Box className={classes.bottomBar}>
          <Button onClick={() => history.push('/event/multi-events')} fullWidth color='primary' variant='contained'>
            Continue to Check-In
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LandingPage;
