import React, { FC } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { LoginForm } from '../display/login-form';
import { ReactComponent as LogoIcon } from '../../../public/logo.svg';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import { i18n_t } from '../../translations/translate';
import {userLogin} from "../../store/thunks/userThunk";

const useStyles = makeStyles((theme: Theme) => ({
  h4: {
    color: '#fff',
    marginTop: '1rem',
    fontFamily: 'Gotham Black',
    fontSize: '2rem',
    letterSpacing: '-0.75',
  },
  error: {
    color: theme.palette.error.main,
  },
  thin: {
    fontWeight: 200,
    fontFamily: 'Gotham Book',
  },
  background: {
    background: '#254aaf',
  },
}));

export const LoginPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const { errorMessage } = useAppSelector((state) => state.user);

  const login = (userName: string, password: string, siteId: string) => {
    dispatch(userLogin({ userName, password, siteId }));
  };

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <div id='Login-page'>
        <Box
          display='flex'
          width='100%'
          alignItems='center'
          padding='2rem'
          flexDirection='column'
          textAlign='center'
          height='100vh'
          className={classes.background}
        >
          <LogoIcon />
          <Typography variant='h4' className={classes.h4}>
            Universal <span className={classes.thin}>| {i18n_t('checkIn')}</span>
          </Typography>
          <Box marginTop='1rem'>
            <LoginForm login={login} />
            {errorMessage ? <Typography className={classes.error}>{errorMessage}</Typography> : null}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default LoginPage;
