import { EventToScan } from './slices/eventSlice';
import { RootState } from './rootReducer';
import {CalendarItem, Event as TsEvent, EventTimeSlots} from '../model/event';
import { Order } from '../model/order';
import { EventLastUpdate, TicketOccurence } from './slices/ticketSlice';

export const selectEvents = (state: RootState): TsEvent[] => state.event.events;

export const selectSelectedEvent = ({
  event: { isMultipleEvents, selectedEvent, toScan },
}: RootState): TsEvent | null => {
  if (isMultipleEvents) {
    return selectedEvent || toScan?.[0]?.event || null;
  } else {
    return toScan?.[0]?.event || null;
  }
};

export const selectIsMultipleEvents = (state: RootState): boolean => state.event.isMultipleEvents;

export const selectEventsToScan = (state: RootState): EventToScan[] => state.event.toScan;

export const selectEventsToScanIds = (state: RootState): string[] => state.event.selectedEventIdsToScan;

export const selectLoadingEventDetails = (state: RootState): boolean => state.event.loadingEventDetails;

export const selectLoadingEvents = (state: RootState): boolean => state.event.loading;

export const selectCurrentSlot = (state: RootState): CalendarItem | null =>
  state.event?.toScan?.[0]?.currentSlot || null;

export const selectMinUntilDate = (state: RootState): Date | null =>
  state.event?.toScan?.[0]?.minUntilDate || null;

export const selectEventTimeSlotsUpdated = (state: RootState): boolean => state.event?.eventTimeSlotsUpdated;

export const selectEventTimeSlots = (state: RootState): EventTimeSlots[] => state.event?.eventTimeSlots;

export const selectOrders = (state: RootState): Order[] => state.order.orders;

export const selectIsOrdersLoading = (state: RootState): boolean => state.order.loading;

export const selectOpenModal = (state: RootState): string => state.modalConfirmation.modalType;

export const selectIsOpenModal = (state: RootState): boolean => state.modalConfirmation.isOpened;

export const selectModalArgs = (state: RootState): string[] => state.modalConfirmation.args;

export const selectModalLoading = (state: RootState): boolean => state.modalConfirmation.loading;

export const selectSelectedOrder = (state: RootState): Order | null => state.order.selectedOrder;

export const selectTicketScanning = (state: RootState): TicketOccurence | null => state.ticket.scanning;

export const selectTicketLoading = (state: RootState): boolean => state.ticket.loadingTickets;

export const selectEventsLastUpdate = (state: RootState): EventLastUpdate => state.ticket.eventsLastUpdate;

export const selectSettingLoading = (state: RootState): boolean => state.settings.loading;
