import { db } from '.';
import { TicketDb } from '../../model/tse/ticket';
import { ReducedTicketType } from '../../model/tse/ticket-type';

export class TicketsService {
  getAllTickets(): Promise<TicketDb[]> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.toArray();
    });
  }

  getTicketByEvent(eventId: string): Promise<TicketDb[]> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.where({ eventId }).toArray();
    });
  }

  getTicketsByOrderId(orderId: string): Promise<TicketDb[]> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.where({ orderId }).toArray();
    });
  }

  getTicketByBarcode(ticketBarCode: string): Promise<TicketDb | undefined> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.where({ ticketBarCode }).first();
    });
  }

  updateTicket(ticket: TicketDb): Promise<number> {
    return db.transaction('rw', db.tickets, async () => {
      return await db.tickets.update(ticket.id, ticket);
    });
  }

  updateTickets = (tickets: TicketDb[]) => {
    return db.transaction('rw', db.tickets, async () => {
      return await db.tickets.bulkPut(tickets);
    });
  };

  addTickets = (tickets: TicketDb[]) => {
    return db.transaction('rw', db.tickets, async () => {
      return await db.tickets.bulkAdd(tickets);
    });
  };

  findTicketTypesByEventId(eventId: string): Promise<ReducedTicketType[]> {
    return db.transaction('r', db.offlineTicketTypes, async () => {
      return await db.offlineTicketTypes.where({ eventId }).toArray();
    });
  }
}
