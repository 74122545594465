import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupPersistReducer } from './persistReducer';
import ThunkExtraArguments from './thunk-extra-arguments';

const persistedReducer = setupPersistReducer(rootReducer);

const thunkArguments = {} as ThunkExtraArguments;
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: thunkArguments,
      },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

thunkArguments.store = store;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const useAppDispatch: () => AppDispatch = useDispatch;

export type ReduxThunkState = { dispatch: (args: unknown) => void; getState: () => RootState };
export type StoreWithState = AppThunk & AppDispatch & RootState & ReduxThunkState;

export const getStoreWithState = (preloadedState?: RootState): StoreWithState =>
  // @ts-ignore
  configureStore({ reducer: rootReducer, preloadedState });

export default store;
