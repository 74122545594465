import React, {FC, useEffect, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, useParams } from 'react-router-dom';
import { EventCalendar } from '../display/event-calendar';
import { EventTitle } from '../display/event-title';
import { FONT_BOLD, FONT_BOOK } from '../../model/constants';
import {
  cleanDeviceOffset,
  fixDateIfApplyAndCleanOffset,
  getTodayAsString,
} from '../../util/event-date-utils';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import { format } from 'date-fns';
import { addMinutes } from 'date-fns/esm';
import { BookForSlotReq, TicketTotalStatus} from '../../model/order';
import {
  selectCurrentSlot,
  selectEventsLastUpdate,
  selectEventsToScan,
  selectEventTimeSlots,
  selectEventTimeSlotsUpdated,
  selectIsMultipleEvents,
  selectIsOrdersLoading,
  selectLoadingEventDetails,
  selectMinUntilDate,
  selectOrders,
  selectSettingLoading,
} from '../../store/selectors';
import ScanButtons from '../display/scan-buttons';
import { findMultipleEventsTimeSlots } from "../../store/thunks/eventThunk";
import { findBooksBySlot } from "../../store/thunks/orderThunk";
import { existsPendingsWithErrors, setOfflineMode } from "../../store/thunks/settingsThunk";
import { retrieveTicketsByEventId } from "../../store/thunks/ticketThunk";
import { getEventTimeSlotParam } from "../../util/event-util";
import {addOrRemoveEventToScan, cleanLoadingEventDetails, updateCurrentTimeSlots} from "../../store/slices/eventSlice";
import { Event as TsEvent } from "../../model/event";
import useInternet from "../../hooks/internet-hook";
import { getTicketTotalsByRecurrentEvent, getTicketTotalsSingleEvent } from "../../util/tickeet-utils";
import { ticketsDBService } from "../../service/db-service";

const useStyles = makeStyles((theme: Theme) => ({
  checkinContainer: {
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  checkinBtn: {
    textTransform: 'uppercase',
    color: '#ffffff',
    backgroundColor: theme.palette.success.main,
    fontSize: '12px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  searchBtn: {
    marginTop: '1.25rem',
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.info.main,
      boxShadow: 'none',
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  marginTop: {
    marginTop: '1.25rem',
  },
  checkinFor: {
    fontFamily: FONT_BOOK,
    marginTop: '20px',
    fontSize: '13px',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.main,
  },
  checkinTime: {
    display: 'flex',
    alignItems: 'center',
  },
  checkinTimeFrom: {
    fontFamily: FONT_BOOK,
    fontSize: '20px',
    fontWeight: 'bold',
  },
  checkinTimeUntil: {
    fontFamily: FONT_BOOK,
    fontSize: '16px',
    marginLeft: '8px',
  },
  checkinAvailableTimeUntil: {
    fontFamily: FONT_BOOK,
    fontSize: '16px',
    paddingTop: '4px',
  },
  vipTitle: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  checkinItem: {
    display: 'flex',
    marginTop: 1,
    alignItems: 'center',
  },
  checkinItemColor: {
    width: 18,
    height: 18,
    borderRadius: 10,
  },
  checkinItemDescription: {
    marginLeft: '8px',
  },
  checkinItemCount: {
    marginLeft: 'auto',
  },
  loadingIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vh - 100px)',
  },
}));

export const EventDetailPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const eventsToScan = useAppSelector(selectEventsToScan);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const loadingEventDetails = useAppSelector(selectLoadingEventDetails);
  const loadingSettings = useAppSelector(selectSettingLoading);
  const currentSlot = useAppSelector(selectCurrentSlot);
  const eventTimeSlotsUpdated = useAppSelector(selectEventTimeSlotsUpdated);
  const minUntilDate = useAppSelector(selectMinUntilDate);
  const orders = useAppSelector(selectOrders);
  const isOrderLoading = useAppSelector(selectIsOrdersLoading);
  const eventsLastUpdate = useAppSelector(selectEventsLastUpdate);
  const toScan = useAppSelector(selectEventsToScan);
  const { id } = useParams();
  const [ selectedEvent, setSelectedEvent ] = useState<TsEvent | undefined>(undefined);
  const { hasInternet } = useInternet();
  const [ ticketTotalsByStatus, setTicketTotalsByStatus ] = useState<TicketTotalStatus>({
    checkedInCount: 0,
    bookedCount: 0,
    totalCount: 0,
    checkedInPer: 0,
    bookedPer: 0,
  });

  useEffect(() => {
    const eventToScan = eventsToScan.find(e => e.event.id === id);
    if (!eventToScan) {
      dispatch(addOrRemoveEventToScan(id));
    }
    setSelectedEvent(eventToScan?.event);
  }, [eventsToScan, id]);

  useEffect(() => {
    const eventTimeSlot = getEventTimeSlotParam(id, selectedEvent?.redeemMinutesAfter );
    dispatch(findMultipleEventsTimeSlots({ eventsTimeSlotParmas: [eventTimeSlot] }));
  }, [selectedEvent]);

  useEffect(() => {
    if(eventTimeSlotsUpdated){
      dispatch(updateCurrentTimeSlots(hasInternet));
      dispatch(cleanLoadingEventDetails());
    }
  }, [eventTimeSlotsUpdated]);

  useEffect(() => {
    if (!isMultipleEvents && selectedEvent?.id === id) {
      const dateSlot = cleanDeviceOffset(eventsToScan?.[0].currentSlot?.recurrenceDateTime);
      dispatch(
        findBooksBySlot({
          eventId: selectedEvent?.id,
          date: getTodayAsString(),
          hour: format(dateSlot, 'H'),
          minute: format(dateSlot, 'm'),
        } as BookForSlotReq),
      );
    }

    const eventsTicketsToRetrieve =
      toScan !== null ? toScan.filter(({ event }) => !eventsLastUpdate[event.id]).map(({event}) => event.id) : [];
    if (eventsTicketsToRetrieve[id]) {
      dispatch(retrieveTicketsByEventId(id));
    } else {
      dispatch(setOfflineMode(eventsTicketsToRetrieve));
    }
    dispatch(existsPendingsWithErrors());
  }, [selectedEvent]);

  useEffect(() => {
    const callGetTickets = async (event: TsEvent) => {
      const tickets = await ticketsDBService.getTicketByEvent(event.id);
      if(event?.isRecurringEvent) {
        setTicketTotalsByStatus(getTicketTotalsByRecurrentEvent(event, tickets, currentSlot));
      } else {
        setTicketTotalsByStatus(getTicketTotalsSingleEvent(event, tickets));
      }
    }
    if(selectedEvent?.id && !!eventsLastUpdate[selectedEvent?.id]) {
      callGetTickets(selectedEvent);
    }
  }, [selectedEvent, currentSlot, loadingEventDetails, eventsLastUpdate]);

  if (loadingEventDetails || loadingSettings || !selectedEvent) {
    return (
      <Box className={classes.loadingIndicator}>
        <Grid>
          <Grid item xs={12} container alignItems="center" style={{justifyContent:"center"}}>
            <CircularProgress />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' color='textSecondary'>Retrieving event information</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const dateSlot = currentSlot?.recurrenceDateTime ? cleanDeviceOffset(currentSlot?.recurrenceDateTime) : new Date();
  const eventSlot = selectedEvent?.recurrenceInfo?.eventTimeSlots.find((e) => e.timeSlot === format(dateSlot, 'HH:mm'));
  const untilDateSlot: Date | null = eventSlot ? addMinutes(dateSlot, eventSlot.intervalInMinutes) : null;

  return (
    <>
      <Box margin='0 1.125rem' paddingTop='1.5rem' borderBottom='1px solid rgba(0, 0, 0, .26)'>
        <Link to='/'>
          <Grid container alignItems='center'>
            <Grid item xs={1} md={2}>
              <Box>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Box>
            </Grid>
            {selectedEvent?.eventStartDate && (
              <Grid item xs={4} md={2}>
                <EventCalendar weekday={true} date={fixDateIfApplyAndCleanOffset(selectedEvent.eventStartDate)} />
              </Grid>
            )}
            <Grid item xs={7} md={8}>
              <EventTitle title={selectedEvent?.title || ''} venue={selectedEvent?.venue?.name || ''} />
            </Grid>
          </Grid>
        </Link>
        <Divider className={classes.marginTop} />
        {selectedEvent?.isRecurringEvent && (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Box>
                <Box className={classes.checkinFor}>Current Time Slot</Box>
                <Box className={classes.checkinTime}>
                  <Box className={classes.checkinTimeFrom}>{format(dateSlot, 'h:mm a')}</Box>
                  {untilDateSlot && <Box className={classes.checkinTimeUntil}>- {format(untilDateSlot, 'h:mm a')}</Box>}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box>
                <Box className={classes.checkinFor}>Check-in until</Box>
                <Box className={classes.checkinTime}>
                  <Box className={classes.checkinAvailableTimeUntil}>{
                    minUntilDate && format(new Date(minUntilDate), 'h:mm a')
                  }</Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
        <Button
          variant='contained'
          color='primary'
          size='medium'
          type='submit'
          fullWidth
          className={classes.searchBtn}
          component={Link}
          to={`/attendees/${id}`}
          disabled={isOrderLoading}
        >
          View / Search Attendees
        </Button>

        {ticketTotalsByStatus.totalCount ? (
          <Box>
            <Box
              style={{
                position: 'relative',
                height: 12,
                width: '100%',
                marginTop: 5,
                marginBottom: 22,
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  height: 12,
                  width: '100%',
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              ></Box>
              <Box
                style={{
                  position: 'absolute',
                  backgroundColor: 'gray',
                  height: 12,
                  width: `${ticketTotalsByStatus.bookedPer}%`,
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              ></Box>
              <Box
                style={{
                  position: 'absolute',
                  backgroundColor: 'black',
                  height: 12,
                  width: `${ticketTotalsByStatus.checkedInPer}%`,
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              ></Box>
            </Box>
            <Box className={classes.checkinItem}>
              <Box className={classes.checkinItemColor} style={{backgroundColor: 'white'}}></Box>
              <Box className={classes.checkinItemDescription}>Total Tickets for this Time Slot</Box>
              <Box className={classes.checkinItemCount}>{ticketTotalsByStatus.totalCount}</Box>
            </Box>
            <Box className={classes.checkinItem}>
              <Box className={classes.checkinItemColor} style={{backgroundColor: 'gray'}}></Box>
              <Box className={classes.checkinItemDescription}>Tickets Sold</Box>
              <Box className={classes.checkinItemCount}>
                {ticketTotalsByStatus.bookedCount || 0} / {ticketTotalsByStatus.totalCount}
              </Box>
            </Box>
            <Box className={classes.checkinItem}>
              <Box className={classes.checkinItemColor} style={{backgroundColor: 'black'}}></Box>
              <Box className={classes.checkinItemDescription}>Checked-In Tickets</Box>
              <Box className={classes.checkinItemCount}>
                {ticketTotalsByStatus.checkedInCount} / {ticketTotalsByStatus.bookedCount}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              style={{
                position: 'relative',
                height: 12,
                width: '100%',
                marginTop: 5,
                marginBottom: 22,
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  height: 12,
                  width: '100%',
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              ></Box>
              <Box
                style={{
                  position: 'absolute',
                  backgroundColor: 'gray',
                  height: 12,
                  width: `${ticketTotalsByStatus.checkedInPer}%`,
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              ></Box>
            </Box>
            <Box className={classes.checkinItem}>
              <Box className={classes.checkinItemDescription}>Checked-In Tickets</Box>
              <Box className={classes.checkinItemCount}>
                {ticketTotalsByStatus.checkedInCount} / {ticketTotalsByStatus.bookedCount}
              </Box>
            </Box>
          </Box>
        )}
        <Divider className={classes.marginTop} />
        <ScanButtons id={id} />
      </Box>
    </>
  );
};

export default EventDetailPage;
