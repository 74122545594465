import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFindOrders, apiGetBookBySlot } from '../../service/api-service';
import { ApiError } from '../../model/api-error';
import { BookForSlotReq, OrderResponse, FindOrdersQuery, Order } from '../../model/order';
import { attendeesDBService, ordersDBService } from '../../service/db-service';

export const findOrders = createAsyncThunk<Order[], FindOrdersQuery[], { rejectValue: ApiError }>(
  'findOrders',
  async (queries, thunkApi) => {
    try {
      if(queries.length > 0){
        const eventIds = queries.map(q => q.eventId);
        const { searchTerm } = queries[0];
        if(eventIds) {
          const orders = await attendeesDBService.findAttendee(eventIds as string[], searchTerm || '');
          if (orders.length) {
            return orders;
          }
        }
      }
      const pendingOrdes: Promise<OrderResponse>[] = [];
      queries.forEach((query) => pendingOrdes.push(apiFindOrders(query)));
      const responses = await Promise.all(pendingOrdes);
      let items: Order[] = [];
      responses.forEach((res, idx) => {
        items = [
          ...items,
          ...res.items.map((it) => {
            it._eventId = queries[idx].eventId;
            return it;
          }),
        ];
      });
      return items;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const findOrdersById = createAsyncThunk<Order, string, { rejectValue: ApiError }>(
  'findOrdersById',
  async (id, thunkApi) => {
    try {
      const order = await ordersDBService.getOrderById(id) as Order;
      return await order;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const findBooksBySlot = createAsyncThunk<Order[], BookForSlotReq, { rejectValue: ApiError }>(
  'findBooksBySlot',
  async (query, thunkApi) => {
    try {
      query.mode = 'scanner';
      query.skip = 0;
      query.take = 1000;
      const res = await apiGetBookBySlot(query);
      return res.items;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);
