import { AttendeesService } from './attendees-db-service';
import { CheckinsService } from './checkins-db-service';
import { OrdersService } from './orders-db-service';
import { TicketsService } from './tickets-db-service';
import { TicketDb} from '../../model/tse/ticket';
import Dexie, { Table } from 'dexie';
import { ReducedTicketType } from '../../model/tse/ticket-type';
import { Event as TsEvent } from "../../model/event";
import {CheckinEvent} from "../../model/checkin";

const DB_NAME = 'us-enterprise';

export enum KeyIndex {
  BARCODE_INDEX = 'ticketBarCode',
  ORDER_ID_INDEX = 'orderIdentifier',
  FULL_NAME_INDEX = 'purchaserFullName',
  EMAIL_INDEX = 'purchaserEmailAddress',
}

export interface OfflineMode {
  id: string;
  event: TsEvent;
  ticketsCount?: number;
}

export enum PendingTicketState {
  PENDING = 'pending',
  ERROR = 'error',
  SYNCED = 'synced',
}

export interface PendingTicket {
  ticket: TicketDb;
  id: string;
  barCode: string;
  state: PendingTicketState;
  message?: string;
  code?: number;
  speedOrder: boolean;
  eventId: string;
  checkinEvents: CheckinEvent[];
}

export class DbService extends Dexie {
  offlineMode!: Table<OfflineMode, string>;
  tickets!: Table<TicketDb, string>;
  pendingCheckin!: Table<PendingTicket, string>;
  offlineTicketTypes!: Table<ReducedTicketType, string>;

  constructor() {
    super(DB_NAME);
    this.version(4).stores({
      offlineMode: '++id',
      tickets: `++id,orderId,orderIdentifier,ticketStatus,ticketBarCode,purchaserEmailAddress,purchaserFirstName,purchaserLastName,*purchaserFullName,eventId`,
      pendingCheckin: '++id,barCode,state',
      offlineTicketTypes: '++id,eventId',
    });
  }

  initializeOffline(event: TsEvent) {
    return this.transaction('rw', this.offlineMode, async () => {
      return await this.offlineMode.put({ id: event.id, event });
    });
  }

  getOfflineEvent(): Promise<OfflineMode[]> {
    return this.transaction('r', this.offlineMode, async () => {
      return await this.offlineMode.toArray();
    });
  }

  resetDatabase() {
    return db.transaction('rw', db.tickets, db.offlineMode, db.offlineTicketTypes, db.pendingCheckin, async () => {
      await Promise.all([db.tickets.clear(), db.offlineMode.clear(), db.offlineTicketTypes.clear()]);
    });
  }
}

export const db = new DbService();
export const ordersDBService = new OrdersService();
export const ticketsDBService = new TicketsService();
export const checkinsDBService = new CheckinsService();
export const attendeesDBService = new AttendeesService();
