import { LoginResponse } from './../../model/user';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError } from '../../model/api-error';
import { LocalStorageConf, TIEMOUT_API, UserMessages } from '../../model/constants';
import { LoginReq } from '../../model/user';
import { apiLogin } from '../../service/api-service';
import {loadSettings} from "../thunks/settingsThunk";

type UserLoginRes = { userName: string; accessToken: string; siteId: string };
export const userLogin = createAsyncThunk<UserLoginRes, LoginReq, { rejectValue: ApiError }>(
  'userLogin',
  async (userLogin, thunkApi) => {
    const { userName, password, siteId } = userLogin;
    if (!siteId || siteId === '' || !userName || userName === '' || !password || password === '') {
      return thunkApi.rejectWithValue({ code: 400, message: UserMessages.GenericLoginError });
    }

    try {
      const res = (await apiLogin(userLogin.userName, userLogin.password)) as LoginResponse;

      // TODO: check if we can remove it.
      localStorage.setItem(LocalStorageConf.SiteId, userLogin.siteId);
      localStorage.setItem(LocalStorageConf.Token, res.access_token);
      localStorage.setItem(LocalStorageConf.Timeout, TIEMOUT_API);

      const loadSettingsRes = await thunkApi.dispatch(loadSettings());
      if (loadSettingsRes?.type === 'loadSettings/rejected') {
        return thunkApi.rejectWithValue({ code: 400, message: UserMessages.GenericLoginError } as ApiError);
      }

      return { userName: userLogin.userName, accessToken: res.access_token, siteId: userLogin.siteId };
    } catch (error) {
      return thunkApi.rejectWithValue({ code: 400, message: UserMessages.GenericLoginError } as ApiError);
    }
  },
);
