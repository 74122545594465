import React, { FC } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '0.1875rem',
    textAlign: 'center',
  },
  cardContent: {
    padding: 0,
    textAlign: 'center',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

interface EventCalendarProps {
  weekday: boolean;
  date: Date | null;
}

export const EventCalendar: FC<EventCalendarProps> = ({ weekday, date }) => {
  const classes = useStyles();

  if (!date) {
    return null;
  }

  return (
    <Grid>
      <Box marginRight='0.55rem'>
        <Card>
          <CardHeader
            component='div'
            title={weekday === true ? format(date, 'EEEE') : ''}
            titleTypographyProps={{ variant: 'subtitle1' }}
            className={classes.cardHeader}
            style={weekday === false ? { height: '11px' } : { height: 'auto' }}
          ></CardHeader>
          <CardContent className={classes.cardContent}>
            <Typography style={weekday === false ? { fontSize: '24px' } : { fontSize: '36px' }} variant='h4'>
              {format(date, 'd')}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
