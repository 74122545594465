import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { EventCalendar } from './event-calendar';
import { fixDateIfApplyAndCleanOffset} from "../../util/event-date-utils";
import { Event as TsEvent } from '../../model/event';

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  title: {
    lineHeight: '1.3125rem',
  },
}));

interface EventHeaderProps {
  event: TsEvent | null;
  goBackLink: string;
  subtitle: string;
  isMultipleEvents: boolean;
}

const EventHeader: FC<EventHeaderProps> = ({ event, goBackLink, subtitle, isMultipleEvents }) => {
  const classes = useStyles();
  return (
    <>
      {event && (
        <Box padding='1.25rem'>
          <Link to={goBackLink}>
            <Grid container alignItems='center'>
              <Grid item xs={1}>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Grid>
              <Grid item xs={2}>
                <EventCalendar weekday={true} date={fixDateIfApplyAndCleanOffset(event.eventStartDate)} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant='h6' className={classes.title}>
                  {isMultipleEvents ? 'Multiple Events' : event.title}
                </Typography>
                <Typography variant='subtitle2' color='secondary'>
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Box>
      )}
    </>
  );
};

export default EventHeader;
