import React, {FC, useEffect, useState} from 'react';
import { Box, Button, makeStyles, Modal, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import {cleanCheckin, clearShowModalConfirm, clearOccurenceFor, setConfirmationState} from '../../store/slices/ticketSlice';
import { cleanDeviceOffset } from '../../util/event-date-utils';
import {ConfirmationState, ConfirmReasonCode} from '../../model/constants';

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '40%',
    margin: '0 1rem',
    background: '#ffffff',
    'border-radius': '4px',
    textAlign: 'center',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

interface CheckInOverrideModalProps {
  checkinScan: (barCode: string, occurrence?: string) => void;
}

export const CheckInOverrideModal: FC<CheckInOverrideModalProps> = ({ checkinScan }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { scanning, occurenceFor, showModalConfirm, confirmReasonCode } = useAppSelector((state) => state.ticket);

  useEffect(() => {
    setOpen(showModalConfirm);
    if (showModalConfirm) {
      dispatch(setConfirmationState(ConfirmationState.Open));
    }
  }, [showModalConfirm]);

  const onCancelEvent = () => {
    dispatch(setConfirmationState(ConfirmationState.Cancel));
    dispatch(clearOccurenceFor());
    dispatch(clearShowModalConfirm());
    dispatch(cleanCheckin());
  };

  const getTitle = (): string => {
    switch (confirmReasonCode) {
      case ConfirmReasonCode.AnotherTimeSlot:
        return 'Wrong Occurrence';
        break;
      case ConfirmReasonCode.DateEventRule:
        return 'It is too late';
        break;
      default:
        return '';
    }
  }

  const getDescription = (): React.ReactNode => {
    switch (confirmReasonCode) {
      case ConfirmReasonCode.AnotherTimeSlot:
        return (
          <>
            This ticket is for a different occurrence. Would you like to check-in for that occurrence?
            <Typography variant='body2'>{format(cleanDeviceOffset(occurenceFor || ''), 'MMM d | h:mm aa')}</Typography>
          </>
        );
        break;
      case ConfirmReasonCode.DateEventRule:
        return (
          <>
            Today it is too late for the event. Would you like to check-in for that?
            <Typography variant='body2'>{format(cleanDeviceOffset(occurenceFor || ''), 'MMM d | h:mm aa')}</Typography>
          </>
        );
        break;
      default:
        return;
    }
  }

  return (
    <Modal open={open}>
      <Box className={classes.modalContent} p='1.25rem'>
        <Typography variant='h6' gutterBottom={true}>
          {getTitle()}
        </Typography>
        <Box marginBottom='1rem'>
          {getDescription()}
        </Box>
        <Button
          className={classes.marginBottom}
          variant='contained'
          color='primary'
          fullWidth={true}
          onClick={() => checkinScan(scanning.barCode || '', occurenceFor || '')}
        >
          Yes, continue check-in
        </Button>
        <Button variant='contained' color='primary' fullWidth={true} onClick={onCancelEvent}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
