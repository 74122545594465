import {GenericMessage, ConfirmationState, ConfirmReasonCode} from './../../model/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckinStatus } from '../../model/checkin';
import {PendingTicket} from "../../service/db-service";
import {
  checkinTicket,
  retrievePendingTickets,
  syncProcess,
  syncTicketsFromServer,
  updateOrderAfterCheckin
} from "../thunks/ticketThunk";

export interface TicketOccurence {
  barCode: string | null;
  occurrence: string | null;
  orderId: string | null;
}

export interface EventLastUpdate {
  [eventId: string]: number;
}

export interface TicketState {
  loading: boolean;
  checkin: CheckinStatus | null;
  confirmationState: ConfirmationState;
  loadingTickets: boolean;
  showModalConfirm: boolean;
  confirmReasonCode: ConfirmReasonCode | null,
  occurenceFor: string | null;
  occurenceEventId: string | null;
  scanning: TicketOccurence;
  errorMessage: string | null;
  eventsLastUpdate: EventLastUpdate;
  syncProcess: boolean;
  syncProcessStart: Date | null;
  pendingTickets: PendingTicket[];
  ticketsCount: {
    [eventId: string]: {
      total: number;
      checkin: number;
    };
  };
}

const initialState: TicketState = {
  loading: false,
  checkin: null,
  confirmationState: ConfirmationState.Ok,
  loadingTickets: false,
  showModalConfirm: false,
  confirmReasonCode: null,
  occurenceFor: null,
  occurenceEventId: null,
  scanning: {
    barCode: null,
    occurrence: null,
    orderId: null,
  },
  errorMessage: null,
  eventsLastUpdate: {},
  syncProcess: false,
  syncProcessStart: null,
  pendingTickets: [],
  ticketsCount: {},
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    cleanCheckin: (state) => {
      state.checkin = null;
      state.errorMessage = null;
      state.confirmationState = ConfirmationState.Cancel;
    },
    clearOccurenceFor: (state) => {
      state.occurenceFor = null;
      state.occurenceEventId = null;
    },
    clearShowModalConfirm: (state) => {
      state.showModalConfirm = false;
      state.confirmReasonCode = null;
    },
    setErrorMessage: (state, { payload }: PayloadAction<string | null>) => {
      state.errorMessage = payload;
    },
    setScanning: (state, { payload }: PayloadAction<TicketState['scanning']>) => {
      state.scanning = payload;
    },
    setEventLastUpdate: (state, { payload }: PayloadAction<{ eventId: string; lastUpdate: number }>) => {
      state.eventsLastUpdate[payload.eventId] = payload.lastUpdate;
    },
    cleanPendingTickets: (state) => {
      state.pendingTickets = [];
    },
    setSyncProcess: (state, { payload }: PayloadAction<boolean>) => {
      state.syncProcess = payload;
    },
    updatePendingState: (state, { payload }: PayloadAction<PendingTicket>) => {
      state.pendingTickets = state.pendingTickets.map((p) => (p.id === payload.id ? payload : p));
    },
    removePendingState: (state, { payload }: PayloadAction<PendingTicket>) => {
      state.pendingTickets = state.pendingTickets.filter((p) => p.id !== payload.id);
    },
    updateCheckinTicketsStateByEventId: (state, { payload }: PayloadAction<{ eventId: string }>) => {
      const { eventId } = payload;
      state.ticketsCount[eventId].checkin = state.ticketsCount[eventId].checkin + 1;
    },
    setConfirmationState: (state, { payload }: PayloadAction<ConfirmationState>) => {
      state.confirmationState = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkinTicket.pending, (state) => {
      state.loading = true;
      state.errorMessage = null;
      state.occurenceFor = null;
      state.occurenceEventId = null;
      state.confirmationState = ConfirmationState.Ok;
    });
    builder.addCase(checkinTicket.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.checkin = payload;
      state.errorMessage = null;
      state.occurenceEventId = payload.eventId || null;
      if (payload.success === false) {
        if (payload.confirmReasonCode) {
          state.showModalConfirm = true;
          state.confirmReasonCode = payload.confirmReasonCode;
        }
        state.occurenceFor = payload.for || null;
      } else {
        state.showModalConfirm = false;
        state.confirmReasonCode = null;
        state.occurenceFor = null;
      }
    });
    builder.addCase(checkinTicket.rejected, (state, { payload }) => {
      state.loading = false;
      state.checkin = null;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(updateOrderAfterCheckin.pending, (state) => {
      state.loadingTickets = true;
    });
    builder.addCase(updateOrderAfterCheckin.fulfilled, (state) => {
      state.loadingTickets = false;
    });
    builder.addCase(updateOrderAfterCheckin.rejected, (state) => {
      state.loadingTickets = false;
    });
    builder.addCase(syncTicketsFromServer.fulfilled, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncTicketsFromServer.rejected, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncProcess.fulfilled, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncProcess.rejected, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(retrievePendingTickets.pending, (state) => {
      state.loading = true;
      state.errorMessage = null;
    });
    builder.addCase(retrievePendingTickets.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pendingTickets = [...state.pendingTickets, ...payload];
    });
    builder.addCase(retrievePendingTickets.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || null;
    });
  },
});

export default ticketSlice.reducer;

export const {
  cleanCheckin,
  setErrorMessage,
  setScanning,
  clearOccurenceFor,
  clearShowModalConfirm,
  setEventLastUpdate,
  setSyncProcess,
  updatePendingState,
  updateCheckinTicketsStateByEventId,
  setConfirmationState,
  cleanPendingTickets,
  removePendingState,
} = ticketSlice.actions;
