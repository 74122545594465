import React, { FC } from 'react';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { generateTheme } from '../../model/theme';
import { Header } from '../display/header';
import { useAppSelector } from '../../store';

export const Layout: FC = ({ children }) => {
  const { logoUrl, headerBackground, accentColor } = useAppSelector((state) => state.settings);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const generatedTheme = generateTheme({
    logoUrl,
    headerBackground,
    accentColor,
  });
  const theme = createTheme(generatedTheme);

  return (
    <div id='layout'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated && <Header />}
        <Box display='flex' width='100%' justifyContent='center' height='calc(100% - 56px)'>
          <Box width='100%'>
            <div className='content'>{children}</div>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default Layout;
